import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initSsSpiritualEnhance202406State = createAction(types.INIT_SS_SPIRITUAL_ENHANCE202406_STATE);

export const getSsSpiritualEnhance202406ListCall = createAction(types.GET_SS_SPIRITUAL_ENHANCE202406_LIST_CALL);
export const getSsSpiritualEnhance202406ListDone = createAction(types.GET_SS_SPIRITUAL_ENHANCE202406_LIST_DONE);
export const getSsSpiritualEnhance202406ListFail = createAction(types.GET_SS_SPIRITUAL_ENHANCE202406_LIST_FAIL);

export const createSsSpiritualEnhance202406Call = createAction(types.CREATE_SS_SPIRITUAL_ENHANCE202406_CALL);
export const createSsSpiritualEnhance202406Done = createAction(types.CREATE_SS_SPIRITUAL_ENHANCE202406_DONE);
export const createSsSpiritualEnhance202406Fail = createAction(types.CREATE_SS_SPIRITUAL_ENHANCE202406_FAIL);

export const updateSsSpiritualEnhance202406Call = createAction(types.UPDATE_SS_SPIRITUAL_ENHANCE202406_CALL);
export const updateSsSpiritualEnhance202406Done = createAction(types.UPDATE_SS_SPIRITUAL_ENHANCE202406_DONE);
export const updateSsSpiritualEnhance202406Fail = createAction(types.UPDATE_SS_SPIRITUAL_ENHANCE202406_FAIL);

export const deleteSsSpiritualEnhance202406Call = createAction(types.DELETE_SS_SPIRITUAL_ENHANCE202406_CALL);
export const deleteSsSpiritualEnhance202406Done = createAction(types.DELETE_SS_SPIRITUAL_ENHANCE202406_DONE);
export const deleteSsSpiritualEnhance202406Fail = createAction(types.DELETE_SS_SPIRITUAL_ENHANCE202406_FAIL);

export const getSsSpiritualEnhance202406RankingCall = createAction(types.GET_SS_SPIRITUAL_ENHANCE202406_RANKING_CALL);
export const getSsSpiritualEnhance202406RankingDone = createAction(types.GET_SS_SPIRITUAL_ENHANCE202406_RANKING_DONE);
export const getSsSpiritualEnhance202406RankingFail = createAction(types.GET_SS_SPIRITUAL_ENHANCE202406_RANKING_FAIL);
