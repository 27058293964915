import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const getChurchesCall = createAction(types.GET_CHURCHES_CALL);
export const getChurchesDone = createAction(types.GET_CHURCHES_DONE);
export const getChurchesFail = createAction(types.GET_CHURCHES_FAIL);

export const createChurchCall = createAction(types.CREATE_CHURCH_CALL);
export const createChurchDone = createAction(types.CREATE_CHURCH_DONE);
export const createChurchFail = createAction(types.CREATE_CHURCH_FAIL);

export const updateChurchCall = createAction(types.UPDATE_CHURCH_CALL);
export const updateChurchDone = createAction(types.UPDATE_CHURCH_DONE);
export const updateChurchFail = createAction(types.UPDATE_CHURCH_FAIL);

export const deleteChurchCall = createAction(types.DELETE_CHURCH_CALL);
export const deleteChurchDone = createAction(types.DELETE_CHURCH_DONE);
export const deleteChurchFail = createAction(types.DELETE_CHURCH_FAIL);
