export const GET_CHURCHES_CALL = 'GET_CHURCHES_CALL';
export const GET_CHURCHES_DONE = 'GET_CHURCHES_DONE';
export const GET_CHURCHES_FAIL = 'GET_CHURCHES_FAIL';

export const CREATE_CHURCH_CALL = 'CREATE_CHURCH_CALL';
export const CREATE_CHURCH_DONE = 'CREATE_CHURCH_DONE';
export const CREATE_CHURCH_FAIL = 'CREATE_CHURCH_FAIL';

export const UPDATE_CHURCH_CALL = 'UPDATE_CHURCH_CALL';
export const UPDATE_CHURCH_DONE = 'UPDATE_CHURCH_DONE';
export const UPDATE_CHURCH_FAIL = 'UPDATE_CHURCH_FAIL';

export const DELETE_CHURCH_CALL = 'DELETE_CHURCH_CALL';
export const DELETE_CHURCH_DONE = 'DELETE_CHURCH_DONE';
export const DELETE_CHURCH_FAIL = 'DELETE_CHURCH_FAIL';
