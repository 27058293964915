import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getMusics = payload => api.get(makePaginationUrl({ baseUrl: `/musics`, ...payload }));

export const createMusic = payload => api.post('/music', payload);

export const getMusic = payload => api.get(`/music/${payload.musicId}`);

export const updateMusic = payload => api.put(`/music/${payload.musicId}`, payload);

export const deleteMusic = payload => api.delete(`/music/${payload.musicId}`);

export const getMusicTags = () => api.get('/musicTags');
