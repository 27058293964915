import { createTheme } from '@mui/material/styles';

import { size, device } from './breakpoints';

export const COLOR_BLUE = '#0069ff';
export const COLOR_PEACH = '#de0681';
export const COLOR_YELLOW = '#fad304';
export const COLOR_PURPLE = '#673B92';
export const COLOR_ORANGE = '#EE9142';

export const NAVIGATION_BAR_HEIGHT = 52;
export const BOTTOM_NAVIGATION_BAR_HEIGHT = 60;
export const GLOBAL_MEDIA_PLAYER_HEIGHT = 52;

const commonProperty = {
  themeTypes: ['dark', 'light'],
  navigationBar: {
    height: NAVIGATION_BAR_HEIGHT,
  },
  globalMediaPlayer: {
    height: GLOBAL_MEDIA_PLAYER_HEIGHT,
  },
  colors: {
    gradient: [
      `-webkit-linear-gradient(left, ${COLOR_BLUE} 0%, ${COLOR_PEACH} 50%, ${COLOR_YELLOW} 100%)`,
      `-webkit-linear-gradient(left, ${COLOR_PURPLE} 0%, ${COLOR_PURPLE} 30%, ${COLOR_PEACH} 66%, ${COLOR_ORANGE} 100%)`,
    ],
    blue: COLOR_BLUE,
    peach: COLOR_PEACH,
    yellow: COLOR_YELLOW,
    purple: COLOR_PURPLE,
  },
  device,
};

const customTheme = {
  dark: {
    ...commonProperty,
    navigationBar: {
      ...commonProperty.navigationBar,
      background: '#303030',
    },
    common: {
      shadows: [
        '0 2px 4px rgba(0, 0, 0, 0.5)',
        '0px 0px 15px 3px rgba(255, 255, 255, 0.25), 0px 0px 1px 0px rgba(255, 255, 255, 0.25), 0px 0px 3px 0px rgba(255, 255, 255, 0.25)',
      ],
      borders: ['#ffffff1f', '#676767'],
      background: ['#1E1E1E', '#303030'],
    },
  },
  light: {
    ...commonProperty,
    navigationBar: {
      ...commonProperty.navigationBar,
      background: '#FFFFFF',
    },
    common: {
      shadows: ['0px 0px 10px 0px rgba(0, 0, 0, 0.15)', '0 1px 6px rgb(32 33 36 / 28%)'],
      borders: ['#0000001f', '#E9E9E9'],
      background: ['#f4f4f4', '#FFFFFF'],
    },
  },
};

const values = {
  xs: size.smTabletMin,
  sm: size.smTabletMin,
  md: size.tabletMin,
  lg: size.desktopMin,
  xl: size.lgDesktopMin,
  xxl: 1440,
  xxxl: 1920,
};

export const darkTheme = createTheme({
  ...customTheme.dark,
  palette: {
    mode: 'dark',
    background: {
      default: '#202020',
    },
  },
  breakpoints: {
    values,
    // keys: [
    // 	...Object.keys(values)
    // ]
  },
});

export const lightTheme = createTheme({
  ...customTheme.light,
  palette: {},
  breakpoints: {
    values,
  },
});
