export const INIT_LECTURE_STATE = 'INIT_LECTURE_STATE';

export const GET_LECTURES_CALL = 'GET_LECTURES_CALL';
export const GET_LECTURES_DONE = 'GET_LECTURES_DONE';
export const GET_LECTURES_FAIL = 'GET_LECTURES_FAIL';

export const CREATE_LECTURE_CALL = 'CREATE_LECTURE_CALL';
export const CREATE_LECTURE_DONE = 'CREATE_LECTURE_DONE';
export const CREATE_LECTURE_FAIL = 'CREATE_LECTURE_FAIL';

export const GET_LECTURE_CALL = 'GET_LECTURE_CALL';
export const GET_LECTURE_DONE = 'GET_LECTURE_DONE';
export const GET_LECTURE_FAIL = 'GET_LECTURE_FAIL';

export const UPDATE_LECTURE_CALL = 'UPDATE_LECTURE_CALL';
export const UPDATE_LECTURE_DONE = 'UPDATE_LECTURE_DONE';
export const UPDATE_LECTURE_FAIL = 'UPDATE_LECTURE_FAIL';

export const DELETE_LECTURE_CALL = 'DELETE_LECTURE_CALL';
export const DELETE_LECTURE_DONE = 'DELETE_LECTURE_DONE';
export const DELETE_LECTURE_FAIL = 'DELETE_LECTURE_FAIL';
