import React from 'react';
import styled from 'styled-components/macro';
import clsx from 'clsx';

import wordBackgroundPath from 'src/assets/background/word-background.jpg';
import { getThumb } from 'src/utils/youtube';

interface IStyles {
  width: number;
  thumbnailUrl?: string;
}

const Background = styled.div<{ $width: number }>`
  flex-shrink: 0;
  background-image: url(${wordBackgroundPath});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: ${props => props.$width}px;
  height: ${props => (props.$width / 16) * 9}px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
`;

const Text = styled.div<IStyles>`
  font-size: ${props => props.width * 0.08}px;
  font-weight: 900;
  padding: 0 ${props => props.width * 0.08}px;
  color: #ffffff;
  white-space: pre-line;
  text-shadow: rgb(154 154 154) 0px 0px 8px;
  overflow: hidden;
  margin: auto 0;
`;

const TextOverflow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ImageContainer = styled.div<{
  $width: number;
}>`
  width: ${props => props.$width}px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
`;

const Image = styled.img`
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

interface IProps {
  width: number;
  title?: string;
  youtubeUrl: string;
  onClick?: () => void;
}

const MediaImageCover = ({ width = 160, title = '', youtubeUrl, onClick }: IProps): JSX.Element => {
  const thumbnailUrl = youtubeUrl ? getThumb(youtubeUrl) : null;

  if (thumbnailUrl) {
    return (
      <ImageContainer $width={width} onClick={onClick}>
        <Image src={thumbnailUrl} width={width} height={(width / 16) * 9} />
      </ImageContainer>
    );
  }

  return (
    <Background $width={width} onClick={onClick}>
      <Text width={width} className={clsx(Text, TextOverflow)}>
        {title}
      </Text>
    </Background>
  );
};

export default MediaImageCover;
