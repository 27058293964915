import storage from '.';

const WORKSPACE_TOKEN = 'workspaceToken';

export const setWorkspaceToken = workspaceToken => storage.saveItem(WORKSPACE_TOKEN, workspaceToken);

export const getWorkspaceToken = () => storage.getItem(WORKSPACE_TOKEN);

export const removeWorkspaceToken = () => {
  storage.removeItem(WORKSPACE_TOKEN);
};
