export const GET_TAGS_CALL = 'GET_TAGS_CALL';
export const GET_TAGS_DONE = 'GET_TAGS_DONE';
export const GET_TAGS_FAIL = 'GET_TAGS_FAIL';

export const CREATE_TAG_CALL = 'CREATE_TAG_CALL';
export const CREATE_TAG_DONE = 'CREATE_TAG_DONE';
export const CREATE_TAG_FAIL = 'CREATE_TAG_FAIL';

export const UPDATE_TAG_CALL = 'UPDATE_TAG_CALL';
export const UPDATE_TAG_DONE = 'UPDATE_TAG_DONE';
export const UPDATE_TAG_FAIL = 'UPDATE_TAG_FAIL';

export const DELETE_TAG_CALL = 'DELETE_TAG_CALL';
export const DELETE_TAG_DONE = 'DELETE_TAG_DONE';
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL';
