import dayjs from 'dayjs';

import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { ISSSpiritualEnhance202406 } from 'src/types';
import { AnyAction } from 'redux';

const timezoneOffset = dayjs().utcOffset();

const initialState = {
  paginatedRecords: {
    data: [] as ISSSpiritualEnhance202406[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
  ranking: {
    userScores: [] as any,
  },
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_SS_SPIRITUAL_ENHANCE202406_STATE: {
      return initialState;
    }
    case types.GET_SS_SPIRITUAL_ENHANCE202406_LIST_CALL: {
      return state;
    }
    case types.GET_SS_SPIRITUAL_ENHANCE202406_LIST_DONE: {
      const paginatedRecords = payload.response.data;
      const updatedPaginatedRecords = camelizeKey({
        ...paginatedRecords,
        data: paginatedRecords?.data
          ?.map((ssSpiritualEnhance202406: ISSSpiritualEnhance202406[]) => camelizeKey(ssSpiritualEnhance202406))
          .map((ssSpiritualEnhance202406: ISSSpiritualEnhance202406) => ({
            ...ssSpiritualEnhance202406,
            date: dayjs(ssSpiritualEnhance202406.date).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            createdAt: dayjs(ssSpiritualEnhance202406.createdAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(ssSpiritualEnhance202406.updatedAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          }))
          .sort((a: ISSSpiritualEnhance202406, b: ISSSpiritualEnhance202406) => dayjs(b.date).diff(dayjs(a.date))),
      });
      return update(state, {
        paginatedRecords: { $set: updatedPaginatedRecords },
      });
    }
    case types.GET_SS_SPIRITUAL_ENHANCE202406_LIST_FAIL: {
      return state;
    }
    case types.GET_SS_SPIRITUAL_ENHANCE202406_RANKING_CALL: {
      return state;
    }
    case types.GET_SS_SPIRITUAL_ENHANCE202406_RANKING_DONE: {
      const ranking = camelizeKey(payload.response.data);
      return update(state, {
        ranking: { $set: ranking },
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
