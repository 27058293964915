import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';
import { dataInitialize } from 'src/services/dataInitialize';

const dataInitializeEpic = (action$: any) =>
  action$.pipe(
    ofType(types.DATA_INITIALIZE_CALL),
    switchMap(({ payload }) =>
      dataInitialize(payload).pipe(
        switchMap(response => of(actions.dataInitializeDone({ response }))),
        catchError(error => of(actions.dataInitializeFail({ error }))),
      ),
    ),
  );

export default [dataInitializeEpic];
