import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { IMusic, IMusicTag } from 'src/types';
import { AnyAction } from 'redux';

const initialState = {
  paginatedMusics: {
    data: [] as IMusic[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
  tags: [] as IMusicTag[],
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_MUSIC_STATE: {
      return initialState;
    }
    case types.GET_MUSICS_CALL: {
      return state;
    }
    case types.GET_MUSICS_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedMusics = payload.response.data;
      const updatedPaginatedMusics = camelizeKey({
        ...paginatedMusics,
        data: paginatedMusics?.data?.map((music: IMusic[]) => camelizeKey(music)),
      });
      return update(state, {
        paginatedMusics: { $set: updatedPaginatedMusics },
      });
    }
    case types.GET_MUSICS_FAIL: {
      return state;
    }
    case types.GET_MUSIC_TAGS_CALL: {
      return state;
    }
    case types.GET_MUSIC_TAGS_DONE: {
      return update(state, {
        tags: { $set: payload.response.data },
      });
    }
    case types.GET_MUSIC_TAGS_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
