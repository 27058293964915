import dayjs from 'dayjs';

import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { IArticle } from 'src/types';
import { AnyAction } from 'redux';

const timezoneOffset = dayjs().utcOffset();

const initialState = {
  paginatedArticles: {
    data: [] as IArticle[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_ARTICLE_STATE: {
      return initialState;
    }
    case types.GET_ARTICLES_CALL: {
      return state;
    }
    case types.GET_ARTICLES_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedArticles = payload.response.data;
      const updatedPaginatedArticles = camelizeKey({
        ...paginatedArticles,
        data: paginatedArticles?.data
          ?.map((article: IArticle[]) => camelizeKey(article))
          .map((article: IArticle) => ({
            ...article,
            publishAt: dayjs(article.publishAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            createdAt: dayjs(article.createdAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(article.updatedAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          }))
          .sort((a: IArticle, b: IArticle) => dayjs(b.publishAt).valueOf() - dayjs(a.publishAt).valueOf()),
      });
      return update(state, {
        paginatedArticles: { $set: updatedPaginatedArticles },
      });
    }
    case types.GET_ARTICLES_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
