export const INIT_USER_STATE = 'INIT_USER_STATE';

export const USER_LOGIN_CALL = 'USER_LOGIN_CALL';
export const USER_LOGIN_DONE = 'USER_LOGIN_DONE';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_LOGOUT_CALL = 'USER_LOGOUT_CALL';
export const USER_LOGOUT_DONE = 'USER_LOGOUT_DONE';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';

export const GET_ALL_WORKSPACE_OF_A_USER_CALL = 'GET_ALL_WORKSPACE_OF_A_USER_CALL';
export const GET_ALL_WORKSPACE_OF_A_USER_DONE = 'GET_ALL_WORKSPACE_OF_A_USER_DONE';
export const GET_ALL_WORKSPACE_OF_A_USER_FAIL = 'GET_ALL_WORKSPACE_OF_A_USER_FAIL';

export const GET_USERS_CALL = 'GET_USERS_CALL';
export const GET_USERS_DONE = 'GET_USERS_DONE';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_USER_CALL = 'GET_USER_CALL';
export const GET_USER_DONE = 'GET_USER_DONE';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const CREATE_USER_CALL = 'CREATE_USER_CALL';
export const CREATE_USER_DONE = 'CREATE_USER_DONE';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER_CALL = 'UPDATE_USER_CALL';
export const UPDATE_USER_DONE = 'UPDATE_USER_DONE';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const GET_USER_FIELDS_OPTIONS_CALL = 'GET_USER_FIELDS_OPTIONS_CALL';
export const GET_USER_FIELDS_OPTIONS_DONE = 'GET_USER_FIELDS_OPTIONS_DONE';
export const GET_USER_FIELDS_OPTIONS_FAIL = 'GET_USER_FIELDS_OPTIONS_FAIL';

export const GET_PROFILE_CALL = 'GET_PROFILE_CALL';
export const GET_PROFILE_DONE = 'GET_PROFILE_DONE';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const RESET_PASSWORD_TO_DEFAULT_CALL = 'RESET_PASSWORD_TO_DEFAULT_CALL';
export const RESET_PASSWORD_TO_DEFAULT_DONE = 'RESET_PASSWORD_TO_DEFAULT_DONE';
export const RESET_PASSWORD_TO_DEFAULT_FAIL = 'RESET_PASSWORD_TO_DEFAULT_FAIL';

export const CHANGE_PASSWORD_CALL = 'CHANGE_PASSWORD_CALL';
export const CHANGE_PASSWORD_DONE = 'CHANGE_PASSWORD_DONE';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const UPDATE_AVATAR_CALL = 'UPDATE_AVATAR_CALL';
export const UPDATE_AVATAR_DONE = 'UPDATE_AVATAR_DONE';
export const UPDATE_AVATAR_FAIL = 'UPDATE_AVATAR_FAIL';

export const SET_CLIENT_IP = 'SET_CLIENT_IP';

export const UPDATE_USER_WORKSPACE_CALL = 'UPDATE_USER_WORKSPACE_CALL';
export const UPDATE_USER_WORKSPACE_DONE = 'UPDATE_USER_WORKSPACE_DONE';
export const UPDATE_USER_WORKSPACE_FAIL = 'UPDATE_USER_WORKSPACE_FAIL';

export const GET_REGISTER_META_CALL = 'GET_REGISTER_META_CALL';
export const GET_REGISTER_META_DONE = 'GET_REGISTER_META_DONE';
export const GET_REGISTER_META_FAIL = 'GET_REGISTER_META_FAIL';

export const REGISTER_USER_CALL = 'REGISTER_USER_CALL';
export const REGISTER_USER_DONE = 'REGISTER_USER_DONE';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const UPDATE_CGM_BARCODE_CALL = 'UPDATE_CGM_BARCODE_CALL';
export const UPDATE_CGM_BARCODE_DONE = 'UPDATE_CGM_BARCODE_DONE';
export const UPDATE_CGM_BARCODE_FAIL = 'UPDATE_CGM_BARCODE_FAIL';

export const UPDATE_NICKNAME_CALL = 'UPDATE_NICKNAME_CALL';
export const UPDATE_NICKNAME_DONE = 'UPDATE_NICKNAME_DONE';
export const UPDATE_NICKNAME_FAIL = 'UPDATE_NICKNAME_FAIL';
