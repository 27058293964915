/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, concatMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getEvents, getEventsInNewcomer, getEventsInAdmin, createEvent, getEvent, updateEvent, deleteEvent } from 'src/services/event';

const getEventsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_EVENTS_CALL),
    concatMap(({ payload = {} }) =>
      getEvents(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getEventsDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getEventsFail({ error }));
        }),
      ),
    ),
  );

const getEventsInNewcomerEpic = action$ =>
  action$.pipe(
    ofType(types.GET_EVENTS_IN_NEWCOMER_CALL),
    concatMap(({ payload = {} }) =>
      getEventsInNewcomer(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getEventsInNewcomerDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getEventsInNewcomerFail({ error }));
        }),
      ),
    ),
  );

const getEventsInAdminEpic = action$ =>
  action$.pipe(
    ofType(types.GET_EVENTS_IN_ADMIN_CALL),
    concatMap(({ payload = {} }) =>
      getEventsInAdmin(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getEventsInAdminDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getEventsInAdminFail({ error }));
        }),
      ),
    ),
  );

const createEventEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_EVENT_CALL),
    switchMap(({ payload = {} }) =>
      createEvent(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createEventDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createEventFail({ error }));
        }),
      ),
    ),
  );

const getEventEpic = action$ =>
  action$.pipe(
    ofType(types.GET_EVENT_CALL),
    switchMap(({ payload = {} }) =>
      getEvent(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getEventDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getEventFail({ error }));
        }),
      ),
    ),
  );

const updateEventEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_EVENT_CALL),
    switchMap(({ payload = {} }) =>
      updateEvent(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateEventDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateEventFail({ error }));
        }),
      ),
    ),
  );

const deleteEventEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_EVENT_CALL),
    switchMap(({ payload = {} }) =>
      deleteEvent(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.deleteEventDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteEventFail({ error }));
        }),
      ),
    ),
  );

export default [getEventsEpic, getEventsInNewcomerEpic, getEventsInAdminEpic, createEventEpic, getEventEpic, updateEventEpic, deleteEventEpic];
