import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getMissions = payload => api.get(makePaginationUrl({ baseUrl: `/missions`, ...payload }));

export const createMission = payload => api.post('/mission', payload);

export const getMission = payload => api.get(`/mission/${payload.missionId}`);

export const updateMission = payload => api.put(`/mission/${payload.missionId}`, payload);

export const deleteMission = payload => api.delete(`/mission/${payload.missionId}`);

export const getMissionRanking = () => api.get(`/mission-ranking`);
