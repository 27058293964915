import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const setActiveWorkspace = createAction(types.SET_ACTIVE_WORKSPACE);

export const getWorkspacesCall = createAction(types.GET_WORKSPACES_CALL);
export const getWorkspacesDone = createAction(types.GET_WORKSPACES_DONE);
export const getWorkspacesFail = createAction(types.GET_WORKSPACES_FAIL);

export const createWorkspaceCall = createAction(types.CREATE_WORKSPACE_CALL);
export const createWorkspaceDone = createAction(types.CREATE_WORKSPACE_DONE);
export const createWorkspaceFail = createAction(types.CREATE_WORKSPACE_FAIL);
