import { combineReducers } from 'redux';

import userState from './user';
import wordState from './word';
import departmentState from './department';
import tagState from './tag';
import churchState from './church';
import eventState from './event';
import articleState from './article';
import roleState from './role';
import musicState from './music';
import lectureState from './lecture';
import attractionState from './attraction';
import workspaceState from './workspace';
import missionState from './mission';
import ssSpiritualEnhance202406State from './ssSpiritualEnhance202406';
import globalMediaPlayerState from './globalMediaPlayer';

const rootReducer = combineReducers({
  userState,
  wordState,
  departmentState,
  tagState,
  churchState,
  eventState,
  articleState,
  roleState,
  workspaceState,
  missionState,
  musicState,
  lectureState,
  attractionState,
  globalMediaPlayerState,
  ssSpiritualEnhance202406State,
});

export default rootReducer;
