/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { addLog } from 'src/services/log';

const addLogEpic = action$ =>
  action$.pipe(
    ofType(types.ADD_LOG_CALL),
    switchMap(({ payload = {} }) =>
      addLog(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.addLogDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.addLogFail({ error }));
        }),
      ),
    ),
  );

export default [addLogEpic];
