import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initLectureState = createAction(types.INIT_LECTURE_STATE);

export const getLecturesCall = createAction(types.GET_LECTURES_CALL);
export const getLecturesDone = createAction(types.GET_LECTURES_DONE);
export const getLecturesFail = createAction(types.GET_LECTURES_FAIL);

export const createLectureCall = createAction(types.CREATE_LECTURE_CALL);
export const createLectureDone = createAction(types.CREATE_LECTURE_DONE);
export const createLectureFail = createAction(types.CREATE_LECTURE_FAIL);

export const getLectureCall = createAction(types.GET_LECTURE_CALL);
export const getLectureDone = createAction(types.GET_LECTURE_DONE);
export const getLectureFail = createAction(types.GET_LECTURE_FAIL);

export const updateLectureCall = createAction(types.UPDATE_LECTURE_CALL);
export const updateLectureDone = createAction(types.UPDATE_LECTURE_DONE);
export const updateLectureFail = createAction(types.UPDATE_LECTURE_FAIL);

export const deleteLectureCall = createAction(types.DELETE_LECTURE_CALL);
export const deleteLectureDone = createAction(types.DELETE_LECTURE_DONE);
export const deleteLectureFail = createAction(types.DELETE_LECTURE_FAIL);
