export const GET_DEPARTMENTS_CALL = 'GET_DEPARTMENTS_CALL';
export const GET_DEPARTMENTS_DONE = 'GET_DEPARTMENTS_DONE';
export const GET_DEPARTMENTS_FAIL = 'GET_DEPARTMENTS_FAIL';

export const CREATE_DEPARTMENT_CALL = 'CREATE_DEPARTMENT_CALL';
export const CREATE_DEPARTMENT_DONE = 'CREATE_DEPARTMENT_DONE';
export const CREATE_DEPARTMENT_FAIL = 'CREATE_DEPARTMENT_FAIL';

export const UPDATE_DEPARTMENT_CALL = 'UPDATE_DEPARTMENT_CALL';
export const UPDATE_DEPARTMENT_DONE = 'UPDATE_DEPARTMENT_DONE';
export const UPDATE_DEPARTMENT_FAIL = 'UPDATE_DEPARTMENT_FAIL';

export const DELETE_DEPARTMENT_CALL = 'DELETE_DEPARTMENT_CALL';
export const DELETE_DEPARTMENT_DONE = 'DELETE_DEPARTMENT_DONE';
export const DELETE_DEPARTMENT_FAIL = 'DELETE_DEPARTMENT_FAIL';
