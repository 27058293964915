export const size = {
  mobileMax: 575,
  smTabletMin: 576,
  smTabletMax: 767,
  tabletMin: 768,
  tabletMax: 991,
  desktopMin: 992,
  desktopMax: 1199,
  lgDesktopMin: 1200,
  lgDesktopMax: 1439,
  xlDesktopMin: 1440,
};

export const device = {
  mobile: `(max-width: ${size.mobileMax}px)`,
  smTablet: `(min-width: ${size.smTabletMin}px) and (max-width: ${size.smTabletMax}px)`,
  tablet: `(min-width: ${size.tabletMin}px) and (max-width: ${size.tabletMax}px)`,
  desktop: `(min-width: ${size.desktopMin}px) and (max-width: ${size.desktopMax}px)`,
  lgDesktop: `(min-width: ${size.lgDesktopMin}px) and (max-width: ${size.lgDesktopMax}px)`,
  xlDesktop: `(min-width: ${size.xlDesktopMin}px)`,
};
