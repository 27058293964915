export const INIT_ARTICLE_STATE = 'INIT_EVENT_STATE';

export const GET_ARTICLES_CALL = 'GET_ARTICLES_CALL';
export const GET_ARTICLES_DONE = 'GET_ARTICLES_DONE';
export const GET_ARTICLES_FAIL = 'GET_ARTICLES_FAIL';

export const CREATE_ARTICLE_CALL = 'CREATE_ARTICLE_CALL';
export const CREATE_ARTICLE_DONE = 'CREATE_ARTICLE_DONE';
export const CREATE_ARTICLE_FAIL = 'CREATE_ARTICLE_FAIL';

export const GET_ARTICLE_CALL = 'GET_ARTICLE_CALL';
export const GET_ARTICLE_DONE = 'GET_ARTICLE_DONE';
export const GET_ARTICLE_FAIL = 'GET_ARTICLE_FAIL';

export const UPDATE_ARTICLE_CALL = 'UPDATE_ARTICLE_CALL';
export const UPDATE_ARTICLE_DONE = 'UPDATE_ARTICLE_DONE';
export const UPDATE_ARTICLE_FAIL = 'UPDATE_ARTICLE_FAIL';

export const DELETE_ARTICLE_CALL = 'DELETE_ARTICLE_CALL';
export const DELETE_ARTICLE_DONE = 'DELETE_ARTICLE_DONE';
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL';
