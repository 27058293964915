import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initWordState = createAction(types.INIT_WORD_STATE);

export const getWordsCall = createAction(types.GET_WORDS_CALL);
export const getWordsDone = createAction(types.GET_WORDS_DONE);
export const getWordsFail = createAction(types.GET_WORDS_FAIL);

export const getUnreadWordsCall = createAction(types.GET_UNREAD_WORDS_CALL);
export const getUnreadWordsDone = createAction(types.GET_UNREAD_WORDS_DONE);
export const getUnreadWordsFail = createAction(types.GET_UNREAD_WORDS_FAIL);

export const getWordCall = createAction(types.GET_WORD_CALL);
export const getWordDone = createAction(types.GET_WORD_DONE);
export const getWordFail = createAction(types.GET_WORD_FAIL);

export const createWordCall = createAction(types.CREATE_WORD_CALL);
export const createWordDone = createAction(types.CREATE_WORD_DONE);
export const createWordFail = createAction(types.CREATE_WORD_FAIL);

export const updateWordCall = createAction(types.UPDATE_WORD_CALL);
export const updateWordDone = createAction(types.UPDATE_WORD_DONE);
export const updateWordFail = createAction(types.UPDATE_WORD_FAIL);

export const deleteWordCall = createAction(types.DELETE_WORD_CALL);
export const deleteWordDone = createAction(types.DELETE_WORD_DONE);
export const deleteWordFail = createAction(types.DELETE_WORD_FAIL);

// Get words in admin
export const getWordsInAdminCall = createAction(types.GET_WORDS_IN_ADMIN_CALL);
export const getWordsInAdminDone = createAction(types.GET_WORDS_IN_ADMIN_DONE);
export const getWordsInAdminFail = createAction(types.GET_WORDS_IN_ADMIN_FAIL);

export const getWordInAdminCall = createAction(types.GET_WORD_IN_ADMIN_CALL);
export const getWordInAdminDone = createAction(types.GET_WORD_IN_ADMIN_DONE);
export const getWordInAdminFail = createAction(types.GET_WORD_IN_ADMIN_FAIL);

// Get words in newcomer
export const getWordsInNewcomerCall = createAction(types.GET_WORDS_IN_NEWCOMER_CALL);
export const getWordsInNewcomerDone = createAction(types.GET_WORDS_IN_NEWCOMER_DONE);
export const getWordsInNewcomerFail = createAction(types.GET_WORDS_IN_NEWCOMER_FAIL);

export const getWordInNewcomerCall = createAction(types.GET_WORD_IN_NEWCOMER_CALL);
export const getWordInNewcomerDone = createAction(types.GET_WORD_IN_NEWCOMER_DONE);
export const getWordInNewcomerFail = createAction(types.GET_WORD_IN_NEWCOMER_FAIL);
