import * as React from 'react';
import styled from 'styled-components';
import { CustomTheme } from 'src/types/theme';

export const Container = styled.div`
  padding: 0 4px;
  background: ${({ theme }: { theme?: CustomTheme }) => theme.colors.gradient[0]};
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: visible;
  display: inline-block;
`;

interface IProps {
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

const GradientText = ({ text, className, style }: IProps): JSX.Element => (
  <Container style={style} className={className}>
    {text}
  </Container>
);

export default GradientText;
