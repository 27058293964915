import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initEventState = createAction(types.INIT_EVENT_STATE);

export const getEventsCall = createAction(types.GET_EVENTS_CALL);
export const getEventsDone = createAction(types.GET_EVENTS_DONE);
export const getEventsFail = createAction(types.GET_EVENTS_FAIL);

export const getEventsInNewcomerCall = createAction(types.GET_EVENTS_IN_NEWCOMER_CALL);
export const getEventsInNewcomerDone = createAction(types.GET_EVENTS_IN_NEWCOMER_DONE);
export const getEventsInNewcomerFail = createAction(types.GET_EVENTS_IN_NEWCOMER_FAIL);

export const getEventsInAdminCall = createAction(types.GET_EVENTS_IN_ADMIN_CALL);
export const getEventsInAdminDone = createAction(types.GET_EVENTS_IN_ADMIN_DONE);
export const getEventsInAdminFail = createAction(types.GET_EVENTS_IN_ADMIN_FAIL);

export const createEventCall = createAction(types.CREATE_EVENT_CALL);
export const createEventDone = createAction(types.CREATE_EVENT_DONE);
export const createEventFail = createAction(types.CREATE_EVENT_FAIL);

export const getEventCall = createAction(types.GET_EVENT_CALL);
export const getEventDone = createAction(types.GET_EVENT_DONE);
export const getEventFail = createAction(types.GET_EVENT_FAIL);

export const updateEventCall = createAction(types.UPDATE_EVENT_CALL);
export const updateEventDone = createAction(types.UPDATE_EVENT_DONE);
export const updateEventFail = createAction(types.UPDATE_EVENT_FAIL);

export const deleteEventCall = createAction(types.DELETE_EVENT_CALL);
export const deleteEventDone = createAction(types.DELETE_EVENT_DONE);
export const deleteEventFail = createAction(types.DELETE_EVENT_FAIL);
