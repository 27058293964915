export const GET_ROLES_CALL = 'GET_ROLES_CALL';
export const GET_ROLES_DONE = 'GET_ROLES_DONE';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';

export const CREATE_ROLE_CALL = 'CREATE_ROLE_CALL';
export const CREATE_ROLE_DONE = 'CREATE_ROLE_DONE';
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL';

export const UPDATE_ROLE_CALL = 'UPDATE_ROLE_CALL';
export const UPDATE_ROLE_DONE = 'UPDATE_ROLE_DONE';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL';

export const DELETE_ROLE_CALL = 'DELETE_ROLE_CALL';
export const DELETE_ROLE_DONE = 'DELETE_ROLE_DONE';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';

export const GET_USERS_BY_ROLE_CALL = 'GET_USERS_BY_ROLE_CALL';
export const GET_USERS_BY_ROLE_DONE = 'GET_USERS_BY_ROLE_DONE';
export const GET_USERS_BY_ROLE_FAIL = 'GET_USERS_BY_ROLE_FAIL';
