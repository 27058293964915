import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getRoles = payload => api.get(makePaginationUrl({ baseUrl: '/roles', ...payload }));

export const getRole = payload => api.get(`/role/${payload.roleId}`);

export const createRole = payload => api.post('/role', payload);

export const updateRole = payload => api.put(`/role/${payload.roleId}`, payload);

export const deleteRole = payload => api.delete(`/role/${payload.roleId}`);

export const getUsersByRole = payload => api.get(`/role/${payload.roleId}/users`);
