import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const globalPlayerInitState = createAction(types.GLOBAL_PLAYER__INIT_STATE);
export const globalPlayerSetPlay = createAction(types.GLOBAL_PLAYER__SET_PLAY);
export const globalPlayerAddToList = createAction(types.GLOBAL_PLAYER__ADD_TO_LIST);
export const globalPlayerRemoveFromList = createAction(types.GLOBAL_PLAYER__REMOVE_FROM_LIST);
export const globalPlayerSetCurrentPlayUID = createAction(types.GLOBAL_PLAYER__SET_CURRENT_PLAY_UID);
export const globalPlayerSetPlaylist = createAction(types.GLOBAL_PLAYER__SET_PLAYLIST);
export const globalPlayerSetMuted = createAction(types.GLOBAL_PLAYER__SET_MUTED);
export const globalPlayerSetOpenPlaylist = createAction(types.GLOBAL_PLAYER__SET_OPEN_PLAYLIST);
