import dayjs from 'dayjs';

import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { IWord } from 'src/types';
import { AnyAction } from 'redux';

const timezoneOffset = dayjs().utcOffset();

const initialState = {
  paginatedWords: {
    data: [] as IWord[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
  paginatedUnreadWords: {
    data: [] as IWord[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
  paginatedWordsInNewcomer: {
    data: [] as IWord[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
  paginatedWordsInAdmin: {
    data: [] as IWord[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_WORD_STATE: {
      return initialState;
    }
    case types.GET_WORDS_CALL: {
      return state;
    }
    case types.GET_WORDS_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedWords = payload.response.data;
      const updatedPaginatedWords = camelizeKey({
        ...paginatedWords,
        data: paginatedWords.data
          .map((word: IWord[]) => camelizeKey(word))
          .map((word: IWord) => ({
            ...word,
            createdAt: dayjs(word.createdAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(word.updatedAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            publishAt: dayjs(word.publishAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            releaseAt: dayjs(word.releaseAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            expireAt: dayjs(word.expireAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          })),
      });
      return update(state, {
        paginatedWords: { $set: updatedPaginatedWords },
      });
    }
    case types.GET_UNREAD_WORDS_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedWords = payload.response.data;
      const updatedPaginatedWords = camelizeKey({
        ...paginatedWords,
        data: paginatedWords.data
          .map((word: IWord[]) => camelizeKey(word))
          .map((word: IWord) => ({
            ...word,
            createdAt: dayjs(word.createdAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(word.updatedAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            publishAt: dayjs(word.publishAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            releaseAt: dayjs(word.releaseAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            expireAt: dayjs(word.expireAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          })),
      });
      return update(state, {
        paginatedUnreadWords: { $set: updatedPaginatedWords },
      });
    }
    case types.GET_WORDS_FAIL: {
      return state;
    }
    // words in admin
    case types.GET_WORDS_IN_ADMIN_CALL: {
      return state;
    }
    case types.GET_WORDS_IN_ADMIN_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedWords = payload.response.data;
      const updatedPaginatedWords = camelizeKey({
        ...paginatedWords,
        data: paginatedWords.data
          .map((word: IWord) => camelizeKey(word))
          .map((word: IWord) => ({
            ...word,
            createdAt: dayjs(word.createdAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(word.updatedAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            publishAt: dayjs(word.publishAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            releaseAt: dayjs(word.releaseAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            expireAt: dayjs(word.expireAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          })),
      });
      return update(state, {
        paginatedWordsInAdmin: { $set: updatedPaginatedWords },
      });
    }
    case types.GET_WORDS_IN_ADMIN_FAIL: {
      return state;
    }
    case types.GET_WORDS_IN_NEWCOMER_DONE: {
      const paginatedWords = payload.response.data;
      const updatedPaginatedWords = camelizeKey({
        ...paginatedWords,
        data: paginatedWords.data
          .map((word: IWord) => camelizeKey(word))
          .map((word: IWord) => ({
            ...word,
            createdAt: dayjs(word.createdAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(word.updatedAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            publishAt: dayjs(word.publishAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            releaseAt: dayjs(word.releaseAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            expireAt: dayjs(word.expireAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          })),
      });
      return update(state, {
        paginatedWordsInNewcomer: { $set: updatedPaginatedWords },
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
