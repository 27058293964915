import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { IEvent } from 'src/types';
import { AnyAction } from 'redux';

const initialState = {
  paginatedEvents: {
    data: [] as IEvent[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
  paginatedEventsInNewcomer: {
    data: [] as IEvent[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
  paginatedEventsInAdmin: {
    data: [] as IEvent[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_EVENT_STATE: {
      return initialState;
    }
    case types.GET_EVENTS_CALL: {
      return state;
    }
    case types.GET_EVENTS_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedEvents = payload.response.data;
      const updatedPaginatedEvents = camelizeKey({
        ...paginatedEvents,
        data: paginatedEvents?.data?.map((event: IEvent[]) => camelizeKey(event)),
      });
      return update(state, {
        paginatedEvents: { $set: updatedPaginatedEvents },
      });
    }
    case types.GET_EVENTS_FAIL: {
      return state;
    }
    // newcomer
    case types.GET_EVENTS_IN_NEWCOMER_CALL: {
      return state;
    }
    case types.GET_EVENTS_IN_NEWCOMER_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedEventsInNewcomer = payload.response.data;
      const updatedPaginatedEventsInNewcomer = camelizeKey({
        ...paginatedEventsInNewcomer,
        data: paginatedEventsInNewcomer?.data?.map((event: IEvent[]) => camelizeKey(event)),
      });
      return update(state, {
        paginatedEventsInNewcomer: { $set: updatedPaginatedEventsInNewcomer },
      });
    }
    case types.GET_EVENTS_IN_NEWCOMER_FAIL: {
      return state;
    }
    // admin
    case types.GET_EVENTS_IN_ADMIN_CALL: {
      return state;
    }
    case types.GET_EVENTS_IN_ADMIN_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedEventsInAdmin = payload.response.data;
      const updatedPaginatedEventsInAdmin = camelizeKey({
        ...paginatedEventsInAdmin,
        data: paginatedEventsInAdmin?.data?.map((event: IEvent[]) => camelizeKey(event)),
      });
      return update(state, {
        paginatedEventsInAdmin: { $set: updatedPaginatedEventsInAdmin },
      });
    }
    case types.GET_EVENTS_IN_ADMIN_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
