/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getArticles, createArticle, getArticle, updateArticle, deleteArticle } from 'src/services/article';

const getArticlesEpic = action$ =>
  action$.pipe(
    ofType(types.GET_ARTICLES_CALL),
    switchMap(({ payload = {} }) =>
      getArticles(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getArticlesDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getArticlesFail({ error }));
        }),
      ),
    ),
  );

const createArticleEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_ARTICLE_CALL),
    switchMap(({ payload = {} }) =>
      createArticle(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createArticleDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createArticleFail({ error }));
        }),
      ),
    ),
  );

const getArticleEpic = action$ =>
  action$.pipe(
    ofType(types.GET_ARTICLE_CALL),
    switchMap(({ payload = {} }) =>
      getArticle(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getArticleDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getArticleFail({ error }));
        }),
      ),
    ),
  );

const updateArticleEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_ARTICLE_CALL),
    switchMap(({ payload = {} }) =>
      updateArticle(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateArticleDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateArticleFail({ error }));
        }),
      ),
    ),
  );

const deleteArticleEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_ARTICLE_CALL),
    switchMap(({ payload = {} }) =>
      deleteArticle(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.deleteArticleDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteArticleFail({ error }));
        }),
      ),
    ),
  );

export default [getArticlesEpic, createArticleEpic, getArticleEpic, updateArticleEpic, deleteArticleEpic];
