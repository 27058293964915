import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const getTagsCall = createAction(types.GET_TAGS_CALL);
export const getTagsDone = createAction(types.GET_TAGS_DONE);
export const getTagsFail = createAction(types.GET_TAGS_FAIL);

export const createTagCall = createAction(types.CREATE_TAG_CALL);
export const createTagDone = createAction(types.CREATE_TAG_DONE);
export const createTagFail = createAction(types.CREATE_TAG_FAIL);

export const updateTagCall = createAction(types.UPDATE_TAG_CALL);
export const updateTagDone = createAction(types.UPDATE_TAG_DONE);
export const updateTagFail = createAction(types.UPDATE_TAG_FAIL);

export const deleteTagCall = createAction(types.DELETE_TAG_CALL);
export const deleteTagDone = createAction(types.DELETE_TAG_DONE);
export const deleteTagFail = createAction(types.DELETE_TAG_FAIL);
