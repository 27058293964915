import * as types from 'src/store/types';
import update from 'immutability-helper';
import { AnyAction } from 'redux';

import { camelizeKey } from 'src/utils/keyFormat';
import { ITag } from 'src/types';

const initialState = {
  tags: [] as ITag[],
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_TAGS_CALL: {
      return state;
    }
    case types.GET_TAGS_DONE: {
      const queryTags = payload.response.data;
      const tags = queryTags.data.map((tag: ITag) => camelizeKey(tag));
      return update(state, {
        tags: { $set: tags },
      });
    }
    case types.GET_TAGS_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
