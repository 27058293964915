import * as React from 'react';
import styled from 'styled-components/macro';
import GradientText from 'src/components/GradientText';

const StyledLogo = styled(GradientText)`
  font-family: 'Anton', sans-serif;
`;

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

const Logo = ({ className, style }: IProps): JSX.Element => <StyledLogo style={style} className={className} text='SKYxFAMILY' />;

export default Logo;
