/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, concatMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getLectures, createLecture, getLecture, updateLecture, deleteLecture } from 'src/services/lecture';

const getLecturesEpic = action$ =>
  action$.pipe(
    ofType(types.GET_LECTURES_CALL),
    concatMap(({ payload = {} }) =>
      getLectures(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getLecturesDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getLecturesFail({ error }));
        }),
      ),
    ),
  );

const createLectureEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_LECTURE_CALL),
    switchMap(({ payload = {} }) =>
      createLecture(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createLectureDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createLectureFail({ error }));
        }),
      ),
    ),
  );

const getLectureEpic = action$ =>
  action$.pipe(
    ofType(types.GET_LECTURE_CALL),
    switchMap(({ payload = {} }) =>
      getLecture(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getLectureDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getLectureFail({ error }));
        }),
      ),
    ),
  );

const updateLectureEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_LECTURE_CALL),
    switchMap(({ payload = {} }) =>
      updateLecture(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateLectureDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateLectureFail({ error }));
        }),
      ),
    ),
  );

const deleteLectureEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_LECTURE_CALL),
    switchMap(({ payload = {} }) =>
      deleteLecture(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.deleteLectureDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteLectureFail({ error }));
        }),
      ),
    ),
  );

export default [getLecturesEpic, createLectureEpic, getLectureEpic, updateLectureEpic, deleteLectureEpic];
