import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getEvents = payload => api.get(makePaginationUrl({ baseUrl: `/events`, ...payload }));

export const getEventsInNewcomer = payload => api.get(makePaginationUrl({ baseUrl: `/events_in_newcomer`, ...payload }));

export const getEventsInAdmin = payload => api.get(makePaginationUrl({ baseUrl: `/events_in_admin`, ...payload }));

export const createEvent = payload => api.post('/event', payload);

export const getEvent = ({ eventId, shouldBeOwner = true }) =>
  api.get(`/event/${eventId}${shouldBeOwner === false ? `?should_be_owner=${shouldBeOwner}` : ''}`);

export const updateEvent = payload => api.put(`/event/${payload.eventId}`, payload);

export const deleteEvent = payload => api.delete(`/event/${payload.eventId}`);
