import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initUserState = createAction(types.INIT_USER_STATE);

export const userLoginCall = createAction(types.USER_LOGIN_CALL);
export const userLoginDone = createAction(types.USER_LOGIN_DONE);
export const userLoginFail = createAction(types.USER_LOGIN_FAIL);

export const userLogoutCall = createAction(types.USER_LOGOUT_CALL);
export const userLogoutDone = createAction(types.USER_LOGOUT_DONE);
export const userLogoutFail = createAction(types.USER_LOGOUT_FAIL);

export const getAllWorkspacesOfAUserCall = createAction(types.GET_ALL_WORKSPACE_OF_A_USER_CALL);
export const getAllWorkspacesOfAUserDone = createAction(types.GET_ALL_WORKSPACE_OF_A_USER_DONE);
export const getAllWorkspacesOfAUserFail = createAction(types.GET_ALL_WORKSPACE_OF_A_USER_FAIL);

export const getUsersCall = createAction(types.GET_USERS_CALL);
export const getUsersDone = createAction(types.GET_USERS_DONE);
export const getUsersFail = createAction(types.GET_USERS_FAIL);

export const getUserCall = createAction(types.GET_USER_CALL);
export const getUserDone = createAction(types.GET_USER_DONE);
export const getUserFail = createAction(types.GET_USER_FAIL);

export const createUserCall = createAction(types.CREATE_USER_CALL);
export const createUserDone = createAction(types.CREATE_USER_DONE);
export const createUserFail = createAction(types.CREATE_USER_FAIL);

export const updateUserCall = createAction(types.UPDATE_USER_CALL);
export const updateUserDone = createAction(types.UPDATE_USER_DONE);
export const updateUserFail = createAction(types.UPDATE_USER_FAIL);

export const getUserFieldsOptionsCall = createAction(types.GET_USER_FIELDS_OPTIONS_CALL);
export const getUserFieldsOptionsDone = createAction(types.GET_USER_FIELDS_OPTIONS_DONE);
export const getUserFieldsOptionsFail = createAction(types.GET_USER_FIELDS_OPTIONS_FAIL);

export const getProfileCall = createAction(types.GET_PROFILE_CALL);
export const getProfileDone = createAction(types.GET_PROFILE_DONE);
export const getProfileFail = createAction(types.GET_PROFILE_FAIL);

export const resetPasswordToDefaultCall = createAction(types.RESET_PASSWORD_TO_DEFAULT_CALL);
export const resetPasswordToDefaultDone = createAction(types.RESET_PASSWORD_TO_DEFAULT_DONE);
export const resetPasswordToDefaultFail = createAction(types.RESET_PASSWORD_TO_DEFAULT_FAIL);

export const changePasswordCall = createAction(types.CHANGE_PASSWORD_CALL);
export const changePasswordDone = createAction(types.CHANGE_PASSWORD_DONE);
export const changePasswordFail = createAction(types.CHANGE_PASSWORD_FAIL);

export const updateAvatarCall = createAction(types.UPDATE_AVATAR_CALL);
export const updateAvatarDone = createAction(types.UPDATE_AVATAR_DONE);
export const updateAvatarFail = createAction(types.UPDATE_AVATAR_FAIL);

export const setClientIP = createAction(types.SET_CLIENT_IP);

export const updateUserWorkspaceCall = createAction(types.UPDATE_USER_WORKSPACE_CALL);
export const updateUserWorkspaceDone = createAction(types.UPDATE_USER_WORKSPACE_DONE);
export const updateUserWorkspaceFail = createAction(types.UPDATE_USER_WORKSPACE_FAIL);

export const getRegisterMetaCall = createAction(types.GET_REGISTER_META_CALL);
export const getRegisterMetaDone = createAction(types.GET_REGISTER_META_DONE);
export const getRegisterMetaFail = createAction(types.GET_REGISTER_META_FAIL);

export const registerUserCall = createAction(types.REGISTER_USER_CALL);
export const registerUserDone = createAction(types.REGISTER_USER_DONE);
export const registerUserFail = createAction(types.REGISTER_USER_FAIL);

export const updateCgmBarcodeCall = createAction(types.UPDATE_CGM_BARCODE_CALL);
export const updateCgmBarcodeDone = createAction(types.UPDATE_CGM_BARCODE_DONE);
export const updateCgmBarcodeFail = createAction(types.UPDATE_CGM_BARCODE_FAIL);

export const updateNicknameCall = createAction(types.UPDATE_NICKNAME_CALL);
export const updateNicknameDone = createAction(types.UPDATE_NICKNAME_DONE);
export const updateNicknameFail = createAction(types.UPDATE_NICKNAME_FAIL);
