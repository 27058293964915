import * as React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { getItem, THEME_MODE } from 'src/utils/localStorage';
import { I18nextProvider } from 'react-i18next';
import { darkTheme, lightTheme } from 'src/theme';
import { ThemeContext } from 'src/contexts/theme';
import store from 'src/store';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

import 'react-toastify/dist/ReactToastify.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw-src.css';

import { useGAInit } from 'src/hooks/useGA';
import Main from 'src/pages/Main';
import logoPath from 'src/assets/images/SKYxFAMILY_logo.png';

import i18n from 'src/i18n';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    color: #303030;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  img {
    object-fit: cover;
  }
`;

const getTheme = (themeMode: string) => {
  switch (themeMode) {
    case 'dark':
      return darkTheme;
    case 'light':
      return lightTheme;
    default:
      return lightTheme;
  }
};

const validHostname = ['localhost', 'staging.skyfamily.cc', 'skyfamily.cc'];

const App = (): JSX.Element => {
  useGAInit();
  const defaultThemeMode = getItem(THEME_MODE) || 'light';
  const [themeMode, setThemeMode] = React.useState(defaultThemeMode);
  const theme = getTheme(themeMode);

  if (!validHostname.includes(window.location.hostname)) {
    console.error('Invalid hostname');
    return <></>;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeContext.Provider value={{ themeMode, setThemeMode }}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <MuiThemeProvider theme={theme}>
                <Helmet>
                  <link rel='icon' type='image/png' href={logoPath} />
                  <link rel='apple-touch-icon' href={logoPath} />
                  <title>SKYxFAMILY</title>
                </Helmet>
                <GlobalStyle />
                <BrowserRouter>
                  <Main />
                </BrowserRouter>
                <ToastContainer position={toast.POSITION.TOP_CENTER} theme={themeMode as 'light' | 'dark'} hideProgressBar={true} autoClose={2500} />
              </MuiThemeProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ThemeContext.Provider>
      </Provider>
    </I18nextProvider>
  );
};

export default App;
