import storage from '.';

const WORKSPACE = 'workspace';

export const setWorkspace = workspace => storage.saveItem(WORKSPACE, workspace);

export const getWorkspace = () => storage.getItem(WORKSPACE);

export const removeWorkspace = () => {
  storage.removeItem(WORKSPACE);
};
