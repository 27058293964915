import * as React from 'react';
import styled from 'styled-components/macro';
import { Route, Routes } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import { withAuthControl } from 'src/hocs/withAuthControl';
import { routePathConfig } from 'src/routes';
import GlobalMediaPlayer from 'src/components/GlobalMediaPlayer';

import NavigationBar from './NavigationBar';
import BottomMenu from './SideMenu/BottomMenu';

const Home = React.lazy(() => import('src/pages/Home'));
const Words = React.lazy(() => import('src/pages/Words'));
const WordDetail = React.lazy(() => import('src/pages/Words/WordDetail'));
const MemberManagement = React.lazy(() => import('src/pages/MemberManagement'));
const MemberUpsert = React.lazy(() => import('src/pages/MemberManagement/Upsert'));
const WordManagement = React.lazy(() => import('src/pages/WordManagement'));
const WordUpsert = React.lazy(() => import('src/pages/WordManagement/Upsert'));
const Profile = React.lazy(() => import('src/pages/Profile'));
const DepartmentManagement = React.lazy(() => import('src/pages/DepartmentManagement'));
const TagManagement = React.lazy(() => import('src/pages/TagManagement'));
const ChurchManagement = React.lazy(() => import('src/pages/ChurchManagement'));
const Event = React.lazy(() => import('src/pages/Event'));
const EventDetail = React.lazy(() => import('src/pages/Event/Detail'));
const EventApply = React.lazy(() => import('src/pages/Event/Apply'));
const EventManagement = React.lazy(() => import('src/pages/EventManagement'));
const EventManagementUpsert = React.lazy(() => import('src/pages/EventManagement/Upsert'));
const WorkspaceManagement = React.lazy(() => import('src/pages/WorkspaceManagement'));
const Article = React.lazy(() => import('src/pages/Article'));
const ArticleDetail = React.lazy(() => import('src/pages/Article/Detail'));
const ArticleApply = React.lazy(() => import('src/pages/Article/Apply'));
const ArticleManagement = React.lazy(() => import('src/pages/ArticleManagement'));
const ArticleManagementUpsert = React.lazy(() => import('src/pages/ArticleManagement/Upsert'));
const RoleManagement = React.lazy(() => import('src/pages/RoleManagement'));
const Analytics = React.lazy(() => import('src/pages/Analytics'));
const Music = React.lazy(() => import('src/pages/Music'));
const MusicManagement = React.lazy(() => import('src/pages/MusicManagement'));
const MusicManagementUpsert = React.lazy(() => import('src/pages/MusicManagement/Upsert'));
const MusicManagementUpsertId = React.lazy(() => import('src/pages/MusicManagement/Upsert'));
const Attraction = React.lazy(() => import('src/pages/Attraction'));
const AttractionManagement = React.lazy(() => import('src/pages/AttractionManagement'));
const AttractionManagementUpsert = React.lazy(() => import('src/pages/AttractionManagement/Upsert'));
const AttractionManagementUpsertId = React.lazy(() => import('src/pages/AttractionManagement/Upsert'));
const Lecture = React.lazy(() => import('src/pages/Lecture'));
const LectureDetail = React.lazy(() => import('src/pages/Lecture/Detail'));
const LectureManagement = React.lazy(() => import('src/pages/LectureManagement'));
const LectureManagementUpsert = React.lazy(() => import('src/pages/LectureManagement/Upsert'));
const LectureManagementUpsertId = React.lazy(() => import('src/pages/LectureManagement/Upsert'));
const Evangelism202406 = React.lazy(() => import('src/pages/Activity/Evangelism202406'));
const SSSpiritualEnhance202406 = React.lazy(() => import('src/pages/Activity/SSSpiritualEnhance202406'));

const BottomActions = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 410;
`;

const Main = (): JSX.Element => {
  return (
    <>
      <NavigationBar />
      <React.Suspense fallback={<LinearProgress />}>
        <Routes>
          <Route path={routePathConfig.home} element={<Home />} />
          <Route path={routePathConfig.word} element={<Words />} />
          <Route path={routePathConfig.wordDetail} element={<WordDetail />} />
          <Route path={routePathConfig.members} element={<MemberManagement />} />
          <Route path={routePathConfig.memberUpsert} element={<MemberUpsert />} />
          <Route path={routePathConfig.memberUpsertId} element={<MemberUpsert />} />
          <Route path={routePathConfig.wordManagement} element={<WordManagement />} />
          <Route path={routePathConfig.wordUpsert} element={<WordUpsert />} />
          <Route path={routePathConfig.wordUpsertId} element={<WordUpsert />} />
          <Route path={routePathConfig.lecture} element={<Lecture />} />
          <Route path={routePathConfig.lectureDetail} element={<LectureDetail />} />
          <Route path={routePathConfig.lectureManagement} element={<LectureManagement />} />
          <Route path={routePathConfig.lectureManagementUpsert} element={<LectureManagementUpsert />} />
          <Route path={routePathConfig.lectureManagementUpsertId} element={<LectureManagementUpsertId />} />
          <Route path={routePathConfig.profile} element={<Profile />} />
          <Route path={routePathConfig.departmentManagement} element={<DepartmentManagement />} />
          <Route path={routePathConfig.tagManagement} element={<TagManagement />} />
          <Route path={routePathConfig.churchManagement} element={<ChurchManagement />} />
          <Route path={routePathConfig.event} element={<Event />} />
          <Route path={routePathConfig.eventDetail} element={<EventDetail />} />
          <Route path={routePathConfig.eventApplyDetail} element={<EventApply />} />
          <Route path={routePathConfig.eventApply} element={<EventApply />} />
          <Route path={routePathConfig.eventManagement} element={<EventManagement />} />
          <Route path={routePathConfig.eventManagementUpsert} element={<EventManagementUpsert />} />
          <Route path={routePathConfig.eventManagementUpsertId} element={<EventManagementUpsert />} />
          <Route path={routePathConfig.workspaceManagement} element={<WorkspaceManagement />} />
          <Route path={routePathConfig.article} element={<Article />} />
          <Route path={routePathConfig.articleDetail} element={<ArticleDetail />} />
          <Route path={routePathConfig.articleApplyDetail} element={<ArticleApply />} />
          <Route path={routePathConfig.articleApply} element={<ArticleApply />} />
          <Route path={routePathConfig.articleManagement} element={<ArticleManagement />} />
          <Route path={routePathConfig.articleManagementUpsert} element={<ArticleManagementUpsert />} />
          <Route path={routePathConfig.articleManagementUpsertId} element={<ArticleManagementUpsert />} />
          <Route path={routePathConfig.roleManagement} element={<RoleManagement />} />
          <Route path={routePathConfig.analytics} element={<Analytics />} />
          <Route path={routePathConfig.music} element={<Music />} />
          <Route path={routePathConfig.musicManagement} element={<MusicManagement />} />
          <Route path={routePathConfig.musicManagementUpsert} element={<MusicManagementUpsert />} />
          <Route path={routePathConfig.musicManagementUpsertId} element={<MusicManagementUpsertId />} />
          <Route path={routePathConfig.attraction} element={<Attraction />} />
          <Route path={routePathConfig.attractionManagement} element={<AttractionManagement />} />
          <Route path={routePathConfig.attractionManagementUpsert} element={<AttractionManagementUpsert />} />
          <Route path={routePathConfig.attractionManagementUpsertId} element={<AttractionManagementUpsertId />} />
          <Route path={routePathConfig.evangelism202406} element={<Evangelism202406 />} />
          <Route path={routePathConfig.ssSpiritualEnhance202406} element={<SSSpiritualEnhance202406 />} />
        </Routes>
      </React.Suspense>
      <BottomActions>
        <GlobalMediaPlayer />
        <BottomMenu />
      </BottomActions>
    </>
  );
};

export default withAuthControl(Main);
