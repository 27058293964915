import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import * as selectors from 'src/store/selectors';
import * as actions from 'src/store/actions';
import { snakeizeKey } from 'src/utils/keyFormat';

export const useLog = () => {
  const dispatch = useDispatch();
  const [pageTitle, setPageTitle] = React.useState('');
  const { profile, clientIP } = useSelector(selectors.selectUser);
  const { activeWorkspace } = useSelector(selectors.selectWorkspace);

  React.useEffect(() => {
    if (!clientIP) {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          const clientIP = data.ip;
          dispatch(actions.setClientIP({ clientIP }));
        });
    }
  }, [clientIP]);

  React.useEffect(() => {
    const handleTitleChange = () => {
      setPageTitle(window?.document?.title || '');
    };

    // 监听文档标题变化事件
    window.addEventListener('DOMContentLoaded', handleTitleChange);
    window.addEventListener('load', handleTitleChange);
    document.addEventListener('visibilitychange', handleTitleChange);

    return () => {
      // 清除事件监听器
      window.removeEventListener('DOMContentLoaded', handleTitleChange);
      window.removeEventListener('load', handleTitleChange);
      document.removeEventListener('visibilitychange', handleTitleChange);
    };
  }, []);

  const log = ({ triggerType, description = '' }: { triggerType: string; description?: string }) => {
    const url = decodeURIComponent(window.location.href);
    const email = profile?.email;
    const workspaceUUID = activeWorkspace?.uuid;
    const userAgent = window.navigator.userAgent;
    const localeTimeString = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const referrerUrl = document.referrer;
    const params = {
      triggerType,
      url,
      email,
      workspaceUUID,
      userAgent,
      localeTimeString,
      referrerUrl,
      clientIP,
      description,
      title: window?.document?.title || '',
    };

    if (!profile.isRoot) {
      dispatch(actions.addLogCall(snakeizeKey(params)));
    }
  };

  return {
    pageTitle,
    log,
  };
};
