import dayjs from 'dayjs';

import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { IMission, IMissionRanking } from 'src/types';
import { AnyAction } from 'redux';

const timezoneOffset = dayjs().utcOffset();

const initialState = {
  paginatedMissions: {
    data: [] as IMission[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
  missionRanking: {
    personalRecords: [],
    departmentRecords: [],
    newcomerDepartmentRecords: [],
    linkStatusRecords: [],
    linkQualityRecords: [],
  } as IMissionRanking,
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_MISSION_STATE: {
      return initialState;
    }
    case types.GET_MISSIONS_CALL: {
      return state;
    }
    case types.GET_MISSIONS_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedMissions = payload.response.data;
      const updatedPaginatedMissions = camelizeKey({
        ...paginatedMissions,
        data: paginatedMissions?.data
          ?.map((lecture: IMission[]) => camelizeKey(lecture))
          .map((lecture: IMission) => ({
            ...lecture,
            preacherAt: dayjs(lecture.preacherAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            createdAt: dayjs(lecture.createdAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(lecture.updatedAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          }))
          .sort((a: IMission, b: IMission) => dayjs(b.preacherAt).diff(dayjs(a.preacherAt))),
      });
      return update(state, {
        paginatedMissions: { $set: updatedPaginatedMissions },
      });
    }
    case types.GET_MISSIONS_FAIL: {
      return state;
    }
    case types.GET_MISSION_RANKING_DONE: {
      const missionRanking = payload.response.data;
      const updatedMissionRanking = camelizeKey(missionRanking);
      return update(state, {
        missionRanking: { $set: updatedMissionRanking },
      });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
