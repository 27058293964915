import * as types from 'src/store/types';
import { AnyAction } from 'redux';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';

import { IAllWorkspacesOfAUser, IWorkspace } from 'src/types';
import { setWorkspace } from 'src/storage/workspaceStorage';

const initialState = {
  activeWorkspace: null as IAllWorkspacesOfAUser,
  paginatedWorkspaces: {
    data: [] as IWorkspace[],
    page: 1,
    pageSize: 100,
    total: 0,
  },
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_ACTIVE_WORKSPACE: {
      setWorkspace(payload.workspace.uuid);
      return {
        ...state,
        activeWorkspace: payload.workspace,
      };
    }
    case types.GET_WORKSPACES_CALL: {
      return state;
    }
    case types.GET_WORKSPACES_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedWorkspaces = payload.response.data;
      const updatedPaginatedWorkspaces = camelizeKey({
        ...paginatedWorkspaces,
        data: paginatedWorkspaces.data.map((workspace: IWorkspace[]) => camelizeKey(workspace)).reverse(),
      });
      return update(state, {
        paginatedWorkspaces: { $set: updatedPaginatedWorkspaces },
      });
    }
    case types.GET_WORKSPACES_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
