export const INIT_SS_SPIRITUAL_ENHANCE202406_STATE = 'INIT_SS_SPIRITUAL_ENHANCE202406_STATE';

export const GET_SS_SPIRITUAL_ENHANCE202406_LIST_CALL = 'GET_SS_SPIRITUAL_ENHANCE202406_LIST_CALL';
export const GET_SS_SPIRITUAL_ENHANCE202406_LIST_DONE = 'GET_SS_SPIRITUAL_ENHANCE202406_LIST_DONE';
export const GET_SS_SPIRITUAL_ENHANCE202406_LIST_FAIL = 'GET_SS_SPIRITUAL_ENHANCE202406_LIST_FAIL';

export const CREATE_SS_SPIRITUAL_ENHANCE202406_CALL = 'CREATE_SS_SPIRITUAL_ENHANCE202406_CALL';
export const CREATE_SS_SPIRITUAL_ENHANCE202406_DONE = 'CREATE_SS_SPIRITUAL_ENHANCE202406_DONE';
export const CREATE_SS_SPIRITUAL_ENHANCE202406_FAIL = 'CREATE_SS_SPIRITUAL_ENHANCE202406_FAIL';

export const UPDATE_SS_SPIRITUAL_ENHANCE202406_CALL = 'UPDATE_SS_SPIRITUAL_ENHANCE202406_CALL';
export const UPDATE_SS_SPIRITUAL_ENHANCE202406_DONE = 'UPDATE_SS_SPIRITUAL_ENHANCE202406_DONE';
export const UPDATE_SS_SPIRITUAL_ENHANCE202406_FAIL = 'UPDATE_SS_SPIRITUAL_ENHANCE202406_FAIL';

export const DELETE_SS_SPIRITUAL_ENHANCE202406_CALL = 'DELETE_SS_SPIRITUAL_ENHANCE202406_CALL';
export const DELETE_SS_SPIRITUAL_ENHANCE202406_DONE = 'DELETE_SS_SPIRITUAL_ENHANCE202406_DONE';
export const DELETE_SS_SPIRITUAL_ENHANCE202406_FAIL = 'DELETE_SS_SPIRITUAL_ENHANCE202406_FAIL';

export const GET_SS_SPIRITUAL_ENHANCE202406_RANKING_CALL = 'GET_SS_SPIRITUAL_ENHANCE202406_RANKING_CALL';
export const GET_SS_SPIRITUAL_ENHANCE202406_RANKING_DONE = 'GET_SS_SPIRITUAL_ENHANCE202406_RANKING_DONE';
export const GET_SS_SPIRITUAL_ENHANCE202406_RANKING_FAIL = 'GET_SS_SPIRITUAL_ENHANCE202406_RANKING_FAIL';
