import { combineEpics } from 'redux-observable';

import common from './common';
import user from './user';
import word from './word';
import dataInitialize from './dataInitialize';
import department from './department';
import tag from './tag';
import church from './church';
import event from './event';
import article from './article';
import role from './role';
import workspace from './workspace';
import analytics from './analytics';
import music from './music';
import lecture from './lecture';
import attraction from './attraction';
import log from './log';
import fingerprint from './fingerprint';
import mission from './mission';
import ssSpiritualEnhance202406 from './ssSpiritualEnhance202406';

export const rootEpic = combineEpics(
  ...common,
  ...user,
  ...word,
  ...dataInitialize,
  ...department,
  ...tag,
  ...church,
  ...event,
  ...article,
  ...role,
  ...workspace,
  ...analytics,
  ...music,
  ...lecture,
  ...attraction,
  ...log,
  ...fingerprint,
  ...mission,
  ...ssSpiritualEnhance202406,
);
