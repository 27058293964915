import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initMissionState = createAction(types.INIT_MISSION_STATE);

export const getMissionsCall = createAction(types.GET_MISSIONS_CALL);
export const getMissionsDone = createAction(types.GET_MISSIONS_DONE);
export const getMissionsFail = createAction(types.GET_MISSIONS_FAIL);

export const createMissionCall = createAction(types.CREATE_MISSION_CALL);
export const createMissionDone = createAction(types.CREATE_MISSION_DONE);
export const createMissionFail = createAction(types.CREATE_MISSION_FAIL);

// export const getMissionCall = createAction(types.GET_MISSION_CALL);
// export const getMissionDone = createAction(types.GET_MISSION_DONE);
// export const getMissionFail = createAction(types.GET_MISSION_FAIL);

export const updateMissionCall = createAction(types.UPDATE_MISSION_CALL);
export const updateMissionDone = createAction(types.UPDATE_MISSION_DONE);
export const updateMissionFail = createAction(types.UPDATE_MISSION_FAIL);

export const deleteMissionCall = createAction(types.DELETE_MISSION_CALL);
export const deleteMissionDone = createAction(types.DELETE_MISSION_DONE);
export const deleteMissionFail = createAction(types.DELETE_MISSION_FAIL);

export const getMissionRankingCall = createAction(types.GET_MISSION_RANKING_CALL);
export const getMissionRankingDone = createAction(types.GET_MISSION_RANKING_DONE);
export const getMissionRankingFail = createAction(types.GET_MISSION_RANKING_FAIL);
