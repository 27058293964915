import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { IAttraction } from 'src/types';
import { AnyAction } from 'redux';

const initialState = {
  paginatedAttractions: {
    data: [] as IAttraction[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_MUSIC_STATE: {
      return initialState;
    }
    case types.GET_ATTRACTIONS_CALL: {
      return state;
    }
    case types.GET_ATTRACTIONS_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedAttractions = payload.response.data;
      const updatedPaginatedAttractions = camelizeKey({
        ...paginatedAttractions,
        data: paginatedAttractions?.data
          ?.map((attraction: IAttraction[]) => camelizeKey(attraction))
          ?.map((d: IAttraction) => ({
            ...d,
            locationInfos: JSON.parse(d.locationInfos as unknown as string) as IAttraction['locationInfos'],
          })),
      });
      return update(state, {
        paginatedAttractions: { $set: updatedPaginatedAttractions },
      });
    }
    case types.GET_ATTRACTIONS_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
