export const routePathConfig = {
  home: '/',
  members: '/members',
  memberUpsert: '/members/upsert', // create member
  memberUpsertId: '/members/upsert/:memberId', // edit member
  word: '/word',
  wordDetail: '/word/:wordId',
  wordManagement: '/wordManagement',
  wordUpsert: '/wordManagement/upsert', // create word
  wordUpsertId: '/wordManagement/upsert/:wordId', // edit word
  lecture: '/lecture',
  lectureDetail: '/lecture/:lectureId',
  lectureManagement: '/lectureManagement',
  lectureManagementUpsert: '/lectureManagement/upsert', // create lecture
  lectureManagementUpsertId: '/lectureManagement/upsert/:lectureId', // edit lecture
  login: '/login',
  register: '/register/:workspaceUid',
  forgotPassword: '/forgotPassword',
  resetPassword: '/resetPassword',
  workspace: '/workspace',
  profile: '/profile',
  departmentManagement: '/departmentManagement',
  tagManagement: '/tagManagement',
  churchManagement: '/churchManagement',
  event: '/event',
  eventDetail: '/event/:eventId',
  eventApply: '/event/apply',
  eventApplyDetail: '/event/apply/:eventId',
  eventManagement: '/eventManagement',
  eventManagementUpsert: '/eventManagement/upsert',
  eventManagementUpsertId: '/eventManagement/upsert/:eventId',
  workspaceManagement: 'workspaceManagement',
  article: '/article',
  articleDetail: '/article/:articleId',
  articleApply: '/article/apply',
  articleApplyDetail: '/article/apply/:articleId',
  articleManagement: '/articleManagement',
  articleManagementUpsert: '/articleManagement/upsert',
  articleManagementUpsertId: '/articleManagement/upsert/:articleId',
  roleManagement: '/roleManagement',
  analytics: '/analytics',
  music: '/music',
  musicManagement: '/musicManagement',
  musicManagementUpsert: '/musicManagement/upsert',
  musicManagementUpsertId: '/musicManagement/upsert/:musicId',
  attraction: '/attraction',
  attractionManagement: '/attractionManagement',
  attractionManagementUpsert: '/attractionManagement/upsert',
  attractionManagementUpsertId: '/attractionManagement/upsert/:attractionId',
  // special activities
  evangelism202406: '/evangelism202406',
  ssSpiritualEnhance202406: '/ssSpiritualEnhance202406',
};
