export const GET_ACTIVITY_OVERVIEW_CALL = 'GET_ACTIVITY_OVERVIEW_CALL';
export const GET_ACTIVITY_OVERVIEW_DONE = 'GET_ACTIVITY_OVERVIEW_DONE';
export const GET_ACTIVITY_OVERVIEW_FAIL = 'GET_ACTIVITY_OVERVIEW_FAIL';

export const GET_ACTIVITY_BY_USER_CALL = 'GET_ACTIVITY_BY_USER_CALL';
export const GET_ACTIVITY_BY_USER_DONE = 'GET_ACTIVITY_BY_USER_DONE';
export const GET_ACTIVITY_BY_USER_FAIL = 'GET_ACTIVITY_BY_USER_FAIL';

export const GET_USER_ANALYTICS_CALL = 'GET_USER_ANALYTICS_CALL';
export const GET_USER_ANALYTICS_DONE = 'GET_USER_ANALYTICS_DONE';
export const GET_USER_ANALYTICS_FAIL = 'GET_USER_ANALYTICS_FAIL';

export const GET_GENDER_ANALYTICS_CALL = 'GET_GENDER_ANALYTICS_CALL';
export const GET_GENDER_ANALYTICS_DONE = 'GET_GENDER_ANALYTICS_DONE';
export const GET_GENDER_ANALYTICS_FAIL = 'GET_GENDER_ANALYTICS_FAIL';

export const GET_AGE_ANALYTICS_CALL = 'GET_AGE_ANALYTICS_CALL';
export const GET_AGE_ANALYTICS_DONE = 'GET_AGE_ANALYTICS_DONE';
export const GET_AGE_ANALYTICS_FAIL = 'GET_AGE_ANALYTICS_FAIL';

export const GET_WORDS_READ_STATUS_BY_USER_CALL = 'GET_WORDS_READ_STATUS_BY_USER_CALL';
export const GET_WORDS_READ_STATUS_BY_USER_DONE = 'GET_WORDS_READ_STATUS_BY_USER_DONE';
export const GET_WORDS_READ_STATUS_BY_USER_FAIL = 'GET_WORDS_READ_STATUS_BY_USER_FAIL';
