import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const getRolesCall = createAction(types.GET_ROLES_CALL);
export const getRolesDone = createAction(types.GET_ROLES_DONE);
export const getRolesFail = createAction(types.GET_ROLES_FAIL);

export const createRoleCall = createAction(types.CREATE_ROLE_CALL);
export const createRoleDone = createAction(types.CREATE_ROLE_DONE);
export const createRoleFail = createAction(types.CREATE_ROLE_FAIL);

export const updateRoleCall = createAction(types.UPDATE_ROLE_CALL);
export const updateRoleDone = createAction(types.UPDATE_ROLE_DONE);
export const updateRoleFail = createAction(types.UPDATE_ROLE_FAIL);

export const deleteRoleCall = createAction(types.DELETE_ROLE_CALL);
export const deleteRoleDone = createAction(types.DELETE_ROLE_DONE);
export const deleteRoleFail = createAction(types.DELETE_ROLE_FAIL);

export const getUsersByRoleCall = createAction(types.GET_USERS_BY_ROLE_CALL);
export const getUsersByRoleDone = createAction(types.GET_USERS_BY_ROLE_DONE);
export const getUsersByRoleFail = createAction(types.GET_USERS_BY_ROLE_FAIL);
