export const INIT_WORD_STATE = 'INIT_WORD_STATE';

export const GET_WORDS_CALL = 'GET_WORDS_CALL';
export const GET_WORDS_DONE = 'GET_WORDS_DONE';
export const GET_WORDS_FAIL = 'GET_WORDS_FAIL';

export const GET_UNREAD_WORDS_CALL = 'GET_UNREAD_WORDS_CALL';
export const GET_UNREAD_WORDS_DONE = 'GET_UNREAD_WORDS_DONE';
export const GET_UNREAD_WORDS_FAIL = 'GET_UNREAD_WORDS_FAIL';

export const GET_WORD_CALL = 'GET_WORD_CALL';
export const GET_WORD_DONE = 'GET_WORD_DONE';
export const GET_WORD_FAIL = 'GET_WORD_FAIL';

export const CREATE_WORD_CALL = 'CREATE_WORD_CALL';
export const CREATE_WORD_DONE = 'CREATE_WORD_DONE';
export const CREATE_WORD_FAIL = 'CREATE_WORD_FAIL';

export const UPDATE_WORD_CALL = 'UPDATE_WORD_CALL';
export const UPDATE_WORD_DONE = 'UPDATE_WORD_DONE';
export const UPDATE_WORD_FAIL = 'UPDATE_WORD_FAIL';

export const DELETE_WORD_CALL = 'DELETE_WORD_CALL';
export const DELETE_WORD_DONE = 'DELETE_WORD_DONE';
export const DELETE_WORD_FAIL = 'DELETE_WORD_FAIL';

// Get words in admin
export const GET_WORDS_IN_ADMIN_CALL = 'GET_WORDS_IN_ADMIN_CALL';
export const GET_WORDS_IN_ADMIN_DONE = 'GET_WORDS_IN_ADMIN_DONE';
export const GET_WORDS_IN_ADMIN_FAIL = 'GET_WORDS_IN_ADMIN_FAIL';

export const GET_WORD_IN_ADMIN_CALL = 'GET_WORD_IN_ADMIN_CALL';
export const GET_WORD_IN_ADMIN_DONE = 'GET_WORD_IN_ADMIN_DONE';
export const GET_WORD_IN_ADMIN_FAIL = 'GET_WORD_IN_ADMIN_FAIL';

// Get words in newcomer
export const GET_WORDS_IN_NEWCOMER_CALL = 'GET_WORDS_IN_NEWCOMER_CALL';
export const GET_WORDS_IN_NEWCOMER_DONE = 'GET_WORDS_IN_NEWCOMER_DONE';
export const GET_WORDS_IN_NEWCOMER_FAIL = 'GET_WORDS_IN_NEWCOMER_FAIL';

export const GET_WORD_IN_NEWCOMER_CALL = 'GET_WORD_IN_NEWCOMER_CALL';
export const GET_WORD_IN_NEWCOMER_DONE = 'GET_WORD_IN_NEWCOMER_DONE';
export const GET_WORD_IN_NEWCOMER_FAIL = 'GET_WORD_IN_NEWCOMER_FAIL';
