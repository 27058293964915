/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getRoles, createRole, updateRole, deleteRole, getUsersByRole } from 'src/services/role';

const getRolesEpic = action$ =>
  action$.pipe(
    ofType(types.GET_ROLES_CALL),
    switchMap(({ payload = {} }) =>
      getRoles(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.getRolesDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getRolesFail({ error }));
        }),
      ),
    ),
  );

const createRoleEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_ROLE_CALL),
    switchMap(({ payload = {} }) =>
      createRole(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createRoleDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createRoleFail({ error }));
        }),
      ),
    ),
  );

const updateRoleEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_ROLE_CALL),
    switchMap(({ payload = {} }) =>
      updateRole(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.updateRoleDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateRoleFail({ error }));
        }),
      ),
    ),
  );

const deleteRoleEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_ROLE_CALL),
    switchMap(({ payload = {} }) =>
      deleteRole(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.deleteRoleDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteRoleFail({ error }));
        }),
      ),
    ),
  );

const getUsersByRoleEpic = action$ =>
  action$.pipe(
    ofType(types.GET_USERS_BY_ROLE_CALL),
    switchMap(({ payload = {} }) =>
      getUsersByRole(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getUsersByRoleDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getUsersByRoleFail({ error }));
        }),
      ),
    ),
  );

export default [getRolesEpic, createRoleEpic, updateRoleEpic, deleteRoleEpic, getUsersByRoleEpic];
