import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import * as actions from 'src/store/actions';
import * as selectors from 'src/store/selectors';
import HorizontalCard from 'src/components/GlobalMediaPlayer/HorizontalCard';
import { device } from 'src/theme/breakpoints';
import { removeHtmlTags } from 'src/utils/common';

const MediaPlayerContainer = styled.div`
  & > * {
    height: auto !important;
    aspect-ratio: 16/9;
  }
  iframe {
    border-radius: 16px;
    overflow: hidden;
  }
  @media ${device.mobile} {
    position: sticky;
    top: 0px;
  }
`;

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  padding: 24px;
  gap: 24px;
  @media ${device.smTablet}, ${device.mobile} {
    grid-template-columns: 1fr;
    padding: 12px;
  }
`;

const Title = styled.div`
  padding-top: 24px;
  font-size: 24px;
  font-weight: 900;
`;

const PlaylistLabel = styled.div`
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 12px; ;
`;

const Description = styled.div`
  margin: 16px 0px;
  padding-top: 12px;
  font-size: 16px;
  white-space: pre-line;
  background: rgb(238 238 238 / 60%);
  border-radius: 16px;
  padding: 12px;
  max-height: 260px;
  overflow-y: auto;
  * {
    margin: 0px;
    line-height: 1.5;
  }
  img {
    width: 100%;
  }
`;

const Detail = styled.div`
  * {
    margin: 0px;
    line-height: 2;
  }
  img {
    width: 100%;
  }
`;

interface IProps {
  handleOnProgress: (props: { currentTime: number; duration: number }) => void;
}

const Playlist = (props: IProps) => {
  const playerRef = React.useRef<ReactPlayer>(null);
  const { handleOnProgress } = props;
  const dispatch = useDispatch();
  const { play, currentPlayUID, playlist = [], muted } = useSelector(selectors.selectGlobalMediaPlayerState);
  const currentPlay = playlist.find(item => item.uuid === currentPlayUID);
  const nextPlayUID =
    playlist[playlist.length - 1]?.uuid === currentPlayUID
      ? playlist[0]?.uuid
      : playlist[playlist.findIndex(item => item.uuid === currentPlayUID) + 1]?.uuid;

  const handleClickItem = (uuid: string) => {
    dispatch(
      actions.globalPlayerSetCurrentPlayUID({
        uuid,
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragEnd = (event: { source: any; destination: any }) => {
    const { source, destination } = event;
    if (!destination) {
      return;
    }
    // 拷貝新的 items (來自 state)
    const newPlaylist = [...playlist];

    // 用 splice 處理拖曳後資料, 組合出新的 items
    // splice(start, deleteCount, item )

    // 從 source.index 剪下被拖曳的元素
    const [remove] = newPlaylist.splice(source.index, 1);

    //在 destination.index 位置貼上被拖曳的元素
    newPlaylist.splice(destination.index, 0, remove);

    dispatch(
      actions.globalPlayerSetPlaylist({
        playlist: newPlaylist,
      }),
    );
  };

  return (
    <Container>
      <div>
        <MediaPlayerContainer style={{ backgroundColor: currentPlay?.youtubeUrl ? '#FFF' : '#EEE' }}>
          <ReactPlayer
            ref={playerRef}
            className='react-player'
            url={currentPlay?.youtubeUrl}
            playing={play}
            muted={muted}
            volume={1}
            width='100%'
            onProgress={() => {
              if (!playerRef || !playerRef.current) {
                return;
              }
              const currentTime = playerRef?.current?.getCurrentTime();
              const duration = playerRef?.current?.getDuration();
              handleOnProgress({
                currentTime,
                duration,
              });
            }}
            onPlay={() => {
              dispatch(
                actions.globalPlayerSetPlay({
                  play: true,
                }),
              );
            }}
            onPause={() => {
              dispatch(
                actions.globalPlayerSetPlay({
                  play: false,
                }),
              );
            }}
            onEnded={() => {
              if (nextPlayUID === currentPlayUID) {
                playerRef.current?.seekTo(0);
              } else {
                handleClickItem(nextPlayUID);
              }
            }}
            controls={true}
          />
        </MediaPlayerContainer>
        <Title>{currentPlay?.name}</Title>
        <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap style={{ margin: '12px 0px' }}>
          {currentPlay?.tags.sort().map(tag => (
            <Chip key={tag} label={`# ${tag}`} color={'default'} variant='outlined' size='small' />
          ))}
        </Stack>
        {currentPlay?.description && <Description dangerouslySetInnerHTML={{ __html: currentPlay?.description }}></Description>}
        {currentPlay?.detail && <Detail dangerouslySetInnerHTML={{ __html: currentPlay?.detail }}></Detail>}
      </div>
      <div>
        <PlaylistLabel>播放清單</PlaylistLabel>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='column-1'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {playlist.map((item, index) => {
                  return (
                    <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <HorizontalCard
                            isActive={item.uuid === currentPlayUID}
                            title={item.name}
                            description={removeHtmlTags(item.description)}
                            youtubeUrl={item.youtubeUrl}
                            onClick={() => {
                              handleClickItem(item.uuid);
                            }}
                            onRemove={(event: React.MouseEvent) => {
                              event.stopPropagation();
                              dispatch(
                                actions.globalPlayerRemoveFromList({
                                  uuid: item.uuid,
                                }),
                              );
                            }}
                          />
                          <Divider />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Container>
  );
};

export default Playlist;
