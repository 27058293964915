/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getTags, createTag, updateTag, deleteTag } from 'src/services/tag';

const getTagsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_TAGS_CALL),
    switchMap(({ payload = {} }) =>
      getTags(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.getTagsDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getTagsFail({ error }));
        }),
      ),
    ),
  );

const createTagEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_TAG_CALL),
    switchMap(({ payload = {} }) =>
      createTag(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createTagDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createTagFail({ error }));
        }),
      ),
    ),
  );

const updateTagEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_TAG_CALL),
    switchMap(({ payload = {} }) =>
      updateTag(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.updateTagDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateTagFail({ error }));
        }),
      ),
    ),
  );

const deleteTagEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_TAG_CALL),
    switchMap(({ payload = {} }) =>
      deleteTag(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.deleteTagDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteTagFail({ error }));
        }),
      ),
    ),
  );

export default [getTagsEpic, createTagEpic, updateTagEpic, deleteTagEpic];
