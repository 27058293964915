/* eslint-disable max-len */
import * as React from 'react';

export const MenuBars = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='far'
    data-icon='bars'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 448 512'
    className='svg-inline--fa fa-bars fa-w-14 fa-3x'
    {...props}
  >
    <path
      fill='currentColor'
      d='M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z'
      className=''
    ></path>
  </svg>
);

export const BrightnessIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg focusable='false' viewBox='0 0 24 24' aria-hidden='true' {...props}>
    <path
      fill='currentColor'
      d='M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z'
    ></path>
  </svg>
);

export const NightIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg focusable='false' viewBox='0 0 24 24' aria-hidden='true' {...props}>
    <path
      fill='currentColor'
      d='M20 8.69V4h-4.69L12 .69 8.69 4H4v4.69L.69 12 4 15.31V20h4.69L12 23.31 15.31 20H20v-4.69L23.31 12 20 8.69zM12 18c-.89 0-1.74-.2-2.5-.55C11.56 16.5 13 14.42 13 12s-1.44-4.5-3.5-5.45C10.26 6.2 11.11 6 12 6c3.31 0 6 2.69 6 6s-2.69 6-6 6z'
    ></path>
  </svg>
);

export const LightBulbIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    aria-hidden='true'
    focusable='false'
    data-prefix='fas'
    data-icon='lightbulb-on'
    role='img'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 640 512'
    className='svg-inline--fa fa-lightbulb-on fa-w-20 fa-3x'
    {...props}
  >
    <path
      fill='currentColor'
      d='M240.06,454.34A32,32,0,0,0,245.42,472l17.1,25.69c5.23,7.91,17.17,14.28,26.64,14.28h61.7c9.47,0,21.41-6.37,26.64-14.28L394.59,472A37.47,37.47,0,0,0,400,454.34L400,416H240ZM319.45,0C217.44.31,144,83,144,176a175,175,0,0,0,43.56,115.78c16.52,18.85,42.36,58.22,52.21,91.44,0,.28.07.53.11.78H400.12c0-.25.07-.5.11-.78,9.85-33.22,35.69-72.59,52.21-91.44A175,175,0,0,0,496,176C496,78.63,416.91-.31,319.45,0ZM320,96a80.09,80.09,0,0,0-80,80,16,16,0,0,1-32,0A112.12,112.12,0,0,1,320,64a16,16,0,0,1,0,32ZM112,192a24,24,0,0,0-24-24H24a24,24,0,0,0,0,48H88A24,24,0,0,0,112,192Zm504-24H552a24,24,0,0,0,0,48h64a24,24,0,0,0,0-48ZM131.08,55.22l-55.42-32a24,24,0,1,0-24,41.56l55.42,32a24,24,0,1,0,24-41.56Zm457.26,264-55.42-32a24,24,0,1,0-24,41.56l55.42,32a24,24,0,0,0,24-41.56Zm-481.26-32-55.42,32a24,24,0,1,0,24,41.56l55.42-32a24,24,0,0,0-24-41.56ZM520.94,100a23.8,23.8,0,0,0,12-3.22l55.42-32a24,24,0,0,0-24-41.56l-55.42,32a24,24,0,0,0,12,44.78Z'
      className=''
    />
  </svg>
);

export const BarcodeIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width='800px' height='800px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M23 4.5V8h-1V4.5A1.502 1.502 0 0 0 20.5 3H17V2h3.5A2.503 2.503 0 0 1 23 4.5zM4.5 22A1.502 1.502 0 0 1 3 20.5V17H2v3.5A2.503 2.503 0 0 0 4.5 23H8v-1zM22 20.5a1.502 1.502 0 0 1-1.5 1.5H17v1h3.5a2.503 2.503 0 0 0 2.5-2.5V17h-1zM3 4.5A1.502 1.502 0 0 1 4.5 3H8V2H4.5A2.503 2.503 0 0 0 2 4.5V8h1zM10 19V6H9v13zM6 6v13h2V6zm8 13V6h-2v13zm3-13v13h2V6zm-2 0v13h1V6z' />
    <path fill='none' d='M0 0h24v24H0z' />
  </svg>
);
