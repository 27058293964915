/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getWorkspaces, createWorkspace } from 'src/services/workspace';

const setActiveWorkspaceEpic = action$ =>
  action$.pipe(
    ofType(types.SET_ACTIVE_WORKSPACE),
    switchMap(() => of(actions.initWordState(), actions.initEventState(), actions.initUserState())), // 切換 workspace 後，要把 previous 的資料清空
  );

const getWorkspacesEpic = action$ =>
  action$.pipe(
    ofType(types.GET_WORKSPACES_CALL),
    switchMap(({ payload = {} }) =>
      getWorkspaces(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.getWorkspacesDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getWorkspacesFail({ error }));
        }),
      ),
    ),
  );

const createWorkspaceEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_WORKSPACE_CALL),
    switchMap(({ payload = {} }) =>
      createWorkspace(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createWorkspaceDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createWorkspaceFail({ error }));
        }),
      ),
    ),
  );

export default [getWorkspacesEpic, createWorkspaceEpic, setActiveWorkspaceEpic];
