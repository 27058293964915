/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, concatMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getMusics, createMusic, getMusic, updateMusic, deleteMusic, getMusicTags } from 'src/services/music';

const getMusicsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_MUSICS_CALL),
    concatMap(({ payload = {} }) =>
      getMusics(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getMusicsDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getMusicsFail({ error }));
        }),
      ),
    ),
  );

const createMusicEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_MUSIC_CALL),
    switchMap(({ payload = {} }) =>
      createMusic(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createMusicDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createMusicFail({ error }));
        }),
      ),
    ),
  );

const getMusicEpic = action$ =>
  action$.pipe(
    ofType(types.GET_MUSIC_CALL),
    switchMap(({ payload = {} }) =>
      getMusic(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getMusicDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getMusicFail({ error }));
        }),
      ),
    ),
  );

const updateMusicEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_MUSIC_CALL),
    switchMap(({ payload = {} }) =>
      updateMusic(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateMusicDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateMusicFail({ error }));
        }),
      ),
    ),
  );

const deleteMusicEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_MUSIC_CALL),
    switchMap(({ payload = {} }) =>
      deleteMusic(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.deleteMusicDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteMusicFail({ error }));
        }),
      ),
    ),
  );

const getMusicTagsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_MUSIC_TAGS_CALL),
    switchMap(({ payload = {} }) =>
      getMusicTags(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getMusicTagsDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getMusicTagsFail({ error }));
        }),
      ),
    ),
  );

export default [getMusicsEpic, createMusicEpic, getMusicEpic, updateMusicEpic, deleteMusicEpic, getMusicTagsEpic];
