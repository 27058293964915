/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getChurches, createChurch, updateChurch, deleteChurch } from 'src/services/church';

const getChurchesEpic = action$ =>
  action$.pipe(
    ofType(types.GET_CHURCHES_CALL),
    switchMap(({ payload = {} }) =>
      getChurches(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.getChurchesDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getChurchesFail({ error }));
        }),
      ),
    ),
  );

const createChurchEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_CHURCH_CALL),
    switchMap(({ payload = {} }) =>
      createChurch(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createChurchDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createChurchFail({ error }));
        }),
      ),
    ),
  );

const updateChurchEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_CHURCH_CALL),
    switchMap(({ payload = {} }) =>
      updateChurch(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.updateChurchDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateChurchFail({ error }));
        }),
      ),
    ),
  );

const deleteChurchEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_CHURCH_CALL),
    switchMap(({ payload = {} }) =>
      deleteChurch(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.deleteChurchDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteChurchFail({ error }));
        }),
      ),
    ),
  );

export default [getChurchesEpic, createChurchEpic, updateChurchEpic, deleteChurchEpic];
