import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getSSSpiritualEnhance202406List = payload => api.get(makePaginationUrl({ baseUrl: `/ss_spiritual_enhance_202406_list`, ...payload }));

export const createSSSpiritualEnhance202406 = payload => api.post('/ss_spiritual_enhance_202406', payload);

export const updateSSSpiritualEnhance202406 = payload => api.put(`/ss_spiritual_enhance_202406/${payload.recordId}`, payload);

export const deleteSSSpiritualEnhance202406 = payload => api.delete(`/ss_spiritual_enhance_202406/${payload.recordId}`);

export const getSSSpiritualEnhance202406Ranking = () => api.get('/ss_spiritual_enhance_202406_ranking');
