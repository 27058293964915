import * as React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ButtonBase } from '@mui/material';

import * as selectors from 'src/store/selectors';
import { routePathConfig } from 'src/routes';

const Button = styled(ButtonBase)`
  background: #eeeeee;
  padding: 8px 20px;
  border-radius: 4px;
  opacity: 0.9;
  font-weight: 700;
  &:hover {
    opacity: 1;
  }
`;

const SwitchWorkspace = () => {
  const navigate = useNavigate();
  const { workspaces } = useSelector(selectors.selectUser);
  const { activeWorkspace } = useSelector(selectors.selectWorkspace);

  if (workspaces.length <= 1) return <></>;

  return (
    <Button
      onClick={() => {
        if (workspaces.length > 1) {
          navigate(routePathConfig.workspace);
        }
      }}
    >
      {activeWorkspace?.name}
    </Button>
  );
};

export default SwitchWorkspace;
