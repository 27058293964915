import * as types from 'src/store/types';
import update from 'immutability-helper';
import { AnyAction } from 'redux';

import { camelizeKey } from 'src/utils/keyFormat';
import { IChurch } from 'src/types';

const initialState = {
  churches: [] as IChurch[],
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_CHURCHES_CALL: {
      return state;
    }
    case types.GET_CHURCHES_DONE: {
      const queryChurches = payload.response.data;
      const churches = queryChurches.data.map((church: IChurch) => camelizeKey(church));
      return update(state, {
        churches: { $set: churches },
      });
    }
    case types.GET_CHURCHES_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
