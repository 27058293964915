export const extractYouTubeVideoId = (youtubeUrl: string) => {
  // 使用正規表示式來搭配不同的 YouTube URL 格式
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed|watch\?v=|live\/)?|youtu\.be\/)([^&\n#?]+)/;
  const match = youtubeUrl.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    // 如果無法匹配，返回null或處理錯誤的方式
    return null;
  }
};

export const getThumb = (youtubeUrl: string, size: 'big' | 'small' = 'big'): string => {
  if (youtubeUrl === null) {
    return '';
  }
  const videoId = extractYouTubeVideoId(youtubeUrl);
  if (size === 'small') {
    return 'https://i.ytimg.com/vi/' + videoId + '/2.jpg';
  }
  return 'https://i.ytimg.com/vi/' + videoId + '/hqdefault.jpg';
};

export const formatYoutubeUrl = (youtubeUrl: string) => {
  if (youtubeUrl === null) {
    return '';
  }
  const videoId = extractYouTubeVideoId(youtubeUrl);
  return `https://youtu.be/${videoId}`;
};

export const isYoutubeUrl = (youtubeUrl: string) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed|watch\?v=|live\/)?|youtu\.be\/)([^&\n#?]+)/;
  const match = youtubeUrl.match(regex);
  return !!match && !!match[1];
};
