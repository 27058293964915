/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError, concatMap } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getDepartments, createDepartment, updateDepartment, deleteDepartment } from 'src/services/department';

const getDepartmentsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_DEPARTMENTS_CALL),
    concatMap(({ payload = {} }) =>
      getDepartments(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.getDepartmentsDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getDepartmentsFail({ error }));
        }),
      ),
    ),
  );

const createDepartmentEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_DEPARTMENT_CALL),
    switchMap(({ payload = {} }) =>
      createDepartment(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createDepartmentDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createDepartmentFail({ error }));
        }),
      ),
    ),
  );

const updateDepartmentEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_DEPARTMENT_CALL),
    switchMap(({ payload = {} }) =>
      updateDepartment(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.updateDepartmentDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateDepartmentFail({ error }));
        }),
      ),
    ),
  );

const deleteDepartmentEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_DEPARTMENT_CALL),
    switchMap(({ payload = {} }) =>
      deleteDepartment(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.deleteDepartmentDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteDepartmentFail({ error }));
        }),
      ),
    ),
  );

export default [getDepartmentsEpic, createDepartmentEpic, updateDepartmentEpic, deleteDepartmentEpic];
