import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { GUIDE_MENU } from 'src/pages/Main/SideMenu/menuLists';
import { useAccessControl } from 'src/hooks/useAccessControl';

export const useBottomNav = () => {
  const theme = useTheme();
  const { hasAccessRight } = useAccessControl();
  const isDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const menus = GUIDE_MENU.filter(menuItem => !['home'].includes(menuItem.key)).filter(menuItem => hasAccessRight({ functionName: menuItem.key }));
  const showBottomNav = isDownSM && menus.length > 0;

  return { showBottomNav };
};
