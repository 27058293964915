import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ChurchOutlinedIcon from '@mui/icons-material/ChurchOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';

import { routePathConfig } from 'src/routes';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';

export const GUIDE_MENU = [
  {
    key: 'home',
    icon: HomeOutlinedIcon,
    routePath: routePathConfig.home,
  },
  {
    key: 'event',
    icon: CampaignOutlinedIcon,
    routePath: routePathConfig.event,
  },
  {
    key: 'article',
    icon: HistoryEduOutlinedIcon,
    routePath: routePathConfig.article,
  },
  {
    key: 'word',
    icon: OndemandVideoIcon,
    routePath: routePathConfig.word,
  },
  {
    key: 'lecture',
    icon: CoPresentOutlinedIcon,
    routePath: routePathConfig.lecture,
  },
  {
    key: 'music',
    icon: MusicNoteOutlinedIcon,
    routePath: routePathConfig.music,
  },
  {
    key: 'attraction',
    icon: PlaceOutlinedIcon,
    routePath: routePathConfig.attraction,
  },
];

export const SUPER_MANAGE_MENU = [
  {
    key: 'workspaceManagement',
    icon: MoveDownOutlinedIcon,
    routePath: routePathConfig.workspaceManagement,
  },
  {
    key: 'churchManagement',
    icon: ChurchOutlinedIcon,
    routePath: routePathConfig.churchManagement,
  },
  {
    key: 'departmentManagement',
    icon: AccountTreeOutlinedIcon,
    routePath: routePathConfig.departmentManagement,
  },
  {
    key: 'tagManagement',
    icon: LocalOfferOutlinedIcon,
    routePath: routePathConfig.tagManagement,
  },
  {
    key: 'roleManagement',
    icon: ManageAccountsOutlinedIcon,
    routePath: routePathConfig.roleManagement,
  },
];

export const MANAGE_MENU = [
  {
    key: 'analytics',
    icon: LeaderboardOutlinedIcon,
    routePath: routePathConfig.analytics,
  },
  {
    key: 'eventManagement',
    icon: CampaignOutlinedIcon,
    routePath: routePathConfig.eventManagement,
  },
  {
    key: 'articleManagement',
    icon: HistoryEduOutlinedIcon,
    routePath: routePathConfig.articleManagement,
  },
  {
    key: 'wordManagement',
    icon: VideoSettingsIcon,
    routePath: routePathConfig.wordManagement,
  },
  {
    key: 'lectureManagement',
    icon: CoPresentOutlinedIcon,
    routePath: routePathConfig.lectureManagement,
  },
  {
    key: 'musicManagement',
    icon: MusicNoteOutlinedIcon,
    routePath: routePathConfig.musicManagement,
  },
  {
    key: 'attractionManagement',
    icon: PlaceOutlinedIcon,
    routePath: routePathConfig.attractionManagement,
  },
  {
    key: 'memberManagement',
    icon: HowToRegOutlinedIcon,
    routePath: routePathConfig.members,
  },
  {
    key: 'logout',
    icon: LogoutOutlinedIcon,
    routePath: routePathConfig.login,
  },
];
