export const INIT_ATTRACTION_STATE = 'INIT_ATTRACTION_STATE';

export const GET_ATTRACTIONS_CALL = 'GET_ATTRACTIONS_CALL';
export const GET_ATTRACTIONS_DONE = 'GET_ATTRACTIONS_DONE';
export const GET_ATTRACTIONS_FAIL = 'GET_ATTRACTIONS_FAIL';

export const CREATE_ATTRACTION_CALL = 'CREATE_ATTRACTION_CALL';
export const CREATE_ATTRACTION_DONE = 'CREATE_ATTRACTION_DONE';
export const CREATE_ATTRACTION_FAIL = 'CREATE_ATTRACTION_FAIL';

export const GET_ATTRACTION_CALL = 'GET_ATTRACTION_CALL';
export const GET_ATTRACTION_DONE = 'GET_ATTRACTION_DONE';
export const GET_ATTRACTION_FAIL = 'GET_ATTRACTION_FAIL';

export const UPDATE_ATTRACTION_CALL = 'UPDATE_ATTRACTION_CALL';
export const UPDATE_ATTRACTION_DONE = 'UPDATE_ATTRACTION_DONE';
export const UPDATE_ATTRACTION_FAIL = 'UPDATE_ATTRACTION_FAIL';

export const DELETE_ATTRACTION_CALL = 'DELETE_ATTRACTION_CALL';
export const DELETE_ATTRACTION_DONE = 'DELETE_ATTRACTION_DONE';
export const DELETE_ATTRACTION_FAIL = 'DELETE_ATTRACTION_FAIL';
