import { from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import i18n from 'src/i18n';

import { setAuthToken, removeAuthToken, getAuthToken } from 'src/storage/tokenStorage';
import { removeWorkspaceToken } from 'src/storage/workspaceTokenStorage';
import { getWorkspace, removeWorkspace } from 'src/storage/workspaceStorage';
import { toast } from 'react-toastify';

const formatResponse = res =>
  from(
    new Promise((resolve, reject) => {
      res.json().then(
        fulfilled => {
          resolve({
            status: res.status,
            statusText: res.statusText,
            data: fulfilled,
          });
        },
        err => {
          reject(err);
        },
      );
    }),
  );

export const oAuth2PasswordRequestForm = ({ method, url, data }) => {
  const params = {
    method,
    headers: new Headers({}),
    body: data,
  };

  return from(fetch(url, params).then(response => response)).pipe(
    mergeMap(formatResponse),
    map(response => {
      const { status } = response;
      if (status === 401) {
        removeAuthToken();
        removeWorkspaceToken();
        removeWorkspace();
        throw response;
      }
      if (status !== 200) {
        throw response;
      }
      const {
        data: { access_token: token },
      } = response;
      setAuthToken(token);
      return response;
    }),
  );
};

const makeParams = ({ method, data, headers, otherConfigs }) => {
  const isFormData = data instanceof FormData;
  if (isFormData) {
    const params = {
      method,
      headers: new Headers({
        Workspace: getWorkspace(),
        'auth-token': getAuthToken(),
      }),
      body: data,
    };
    return params;
  }
  const params = {
    method,
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
      Workspace: getWorkspace(),
      'auth-token': getAuthToken(),
      ...headers,
    }),
    ...otherConfigs,
  };
  return params;
};

export const request = ({ method, url, data, headers, otherConfigs = {} }) => {
  const params = makeParams({ method, data, headers, otherConfigs });

  return from(fetch(url, params).then(response => response)).pipe(
    mergeMap(formatResponse),
    map(response => {
      const { status, data } = response;
      if (status === 401) {
        // HTTP_401_UNAUTHORIZED
        const { detail } = data;
        // eslint-disable-next-line no-debugger
        removeAuthToken();
        removeWorkspaceToken();
        removeWorkspace();
        if (detail) {
          toast.error(i18n.t(`toast_message:${detail.toLowerCase()}`));
        }
        setTimeout(() => {
          // 留點時間顯示錯誤訊息給使用者
          window.location.href = '/login';
        }, 2000);
      }
      if (status !== 200) {
        throw response;
      }

      return response;
    }),
  );
};
