/* eslint-disable @typescript-eslint/no-explicit-any */
import { snakeCase } from 'lodash';

export const getUniqueObjList = ({ list, key }: any) => {
  let uniqueItems = [] as any;
  list.forEach((item: any) => {
    const foundItem = uniqueItems.find((uniqueItem: any) => uniqueItem[key] === item[key]);
    if (!foundItem) {
      uniqueItems = [...uniqueItems, item];
    }
  });
  return uniqueItems;
};

export const makePaginationUrl = ({ baseUrl, search, ...params }: { baseUrl: string; search: string }) => {
  let count = 0;
  let url = baseUrl;
  const searchParam = search ? `q=${search}` : '';

  if (searchParam) {
    url = url + (count === 0 ? '?' : '&') + searchParam;
    count += 1;
  }
  Object.keys(params).map((obj: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (params[obj] && typeof params[obj] !== 'function') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      url = url + (count === 0 ? '?' : '&') + `${snakeCase(obj)}=${params[obj]}`;
      count += 1;
    }
  });
  return url;
};

export const removeHtmlTags = (str: string = ''): string => {
  if (str === null || str === '') return '';
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str
    .replace(/(<([^>]+)>)/gi, '')
    .replace('&lt;', '<')
    .replace('&gt;', '>')
    .replace('&amp;', '&')
    .replace('&quot;', '"')
    .replace('&apos;', "'")
    .replace('&nbsp;', ' ');
};

export async function getVideoThumbnail({ videoUrl, currentTime }: { videoUrl: string; currentTime?: number }): Promise<string | null> {
  try {
    const response = await fetch(videoUrl);
    const blob = await response.blob();
    const video = document.createElement('video');
    const objectURL = URL.createObjectURL(blob);

    video.src = objectURL;
    video.muted = true;
    video.autoplay = false;
    video.playsInline = true;
    video.preload = 'metadata';
    if (currentTime && !isNaN(currentTime)) {
      video.currentTime = currentTime; // 设置视频当前时间(秒)
    }

    return new Promise(resolve => {
      video.onloadeddata = () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnailUrl = canvas.toDataURL('image/jpeg');
        resolve(thumbnailUrl);
        URL.revokeObjectURL(objectURL);
      };
    });
  } catch (error) {
    console.error('Error getting video thumbnail:', error);
    return null;
  }
}

export const dataUrlToFile = ({ dataUrl, filename = 'image' }: { dataUrl: string; filename: string }) => {
  // 解析 Data URL，獲取數據部分
  const [, data] = dataUrl.split(';base64,');
  const byteCharacters = atob(data); // 解碼 Base64 字符串

  // 轉換為 Uint8Array
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // 創建 Blob 對象
  const blob = new Blob([byteArray], { type: 'application/octet-stream' });

  // 創建文件對象
  const file = new File([blob], filename, { type: blob.type });

  return file;
};
