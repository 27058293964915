import storage from '.';

const TOKEN = 'token';

export const setAuthToken = token => storage.saveItem(TOKEN, token);

export const removeAuthToken = () => {
  storage.removeItem(TOKEN);
};

export const getAuthToken = () => storage.getItem(TOKEN);
