/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, concatMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import {
  getSSSpiritualEnhance202406List,
  createSSSpiritualEnhance202406,
  updateSSSpiritualEnhance202406,
  deleteSSSpiritualEnhance202406,
  getSSSpiritualEnhance202406Ranking,
} from 'src/services/ssSpiritualEnhance202406';

const getSSSpiritualEnhance202406ListEpic = action$ =>
  action$.pipe(
    ofType(types.GET_SS_SPIRITUAL_ENHANCE202406_LIST_CALL),
    concatMap(({ payload = {} }) =>
      getSSSpiritualEnhance202406List(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getSsSpiritualEnhance202406ListDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getSsSpiritualEnhance202406ListFail({ error }));
        }),
      ),
    ),
  );

const createSSSpiritualEnhance202406Epic = action$ =>
  action$.pipe(
    ofType(types.CREATE_SS_SPIRITUAL_ENHANCE202406_CALL),
    switchMap(({ payload = {} }) =>
      createSSSpiritualEnhance202406(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createSsSpiritualEnhance202406Done({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createSsSpiritualEnhance202406Fail({ error }));
        }),
      ),
    ),
  );

const updateSSSpiritualEnhance202406Epic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_SS_SPIRITUAL_ENHANCE202406_CALL),
    switchMap(({ payload = {} }) =>
      updateSSSpiritualEnhance202406(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateSsSpiritualEnhance202406Done({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateSsSpiritualEnhance202406Fail({ error }));
        }),
      ),
    ),
  );

const deleteSSSpiritualEnhance202406Epic = action$ =>
  action$.pipe(
    ofType(types.DELETE_SS_SPIRITUAL_ENHANCE202406_CALL),
    switchMap(({ payload = {} }) =>
      deleteSSSpiritualEnhance202406(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.deleteSsSpiritualEnhance202406Done({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteSsSpiritualEnhance202406Fail({ error }));
        }),
      ),
    ),
  );

const getSSSpiritualEnhance202406RankingEpic = action$ => {
  return action$.pipe(
    ofType(types.GET_SS_SPIRITUAL_ENHANCE202406_RANKING_CALL),
    switchMap(({ payload = {} }) => {
      return getSSSpiritualEnhance202406Ranking(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getSsSpiritualEnhance202406RankingDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getSsSpiritualEnhance202406RankingFail({ error }));
        }),
      );
    }),
  );
};

export default [
  getSSSpiritualEnhance202406RankingEpic,
  getSSSpiritualEnhance202406ListEpic,
  createSSSpiritualEnhance202406Epic,
  updateSSSpiritualEnhance202406Epic,
  deleteSSSpiritualEnhance202406Epic,
];
