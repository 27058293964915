/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, concatMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import {
  getMissions,
  createMission,
  // getMission,
  updateMission,
  deleteMission,
  getMissionRanking,
} from 'src/services/mission';

const getMissionsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_MISSIONS_CALL),
    concatMap(({ payload = {} }) =>
      getMissions(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getMissionsDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getMissionsFail({ error }));
        }),
      ),
    ),
  );

const createMissionEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_MISSION_CALL),
    switchMap(({ payload = {} }) =>
      createMission(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createMissionDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createMissionFail({ error }));
        }),
      ),
    ),
  );

// const getMissionEpic = action$ =>
//   action$.pipe(
//     ofType(types.GET_MISSION_CALL),
//     switchMap(({ payload = {} }) =>
//       getMission(payload).pipe(
//         switchMap(response => {
//           const { onSuccess } = payload;
//           if (onSuccess) onSuccess(response);
//           return of(actions.getMissionDone({ response }));
//         }),
//         catchError(error => {
//           const { onError } = payload;
//           if (onError) onError(error);
//           return of(actions.getMissionFail({ error }));
//         }),
//       ),
//     ),
//   );

const updateMissionEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_MISSION_CALL),
    switchMap(({ payload = {} }) =>
      updateMission(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateMissionDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateMissionFail({ error }));
        }),
      ),
    ),
  );

const deleteMissionEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_MISSION_CALL),
    switchMap(({ payload = {} }) =>
      deleteMission(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.deleteMissionDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteMissionFail({ error }));
        }),
      ),
    ),
  );

const getMissionRankingEpic = action$ =>
  action$.pipe(
    ofType(types.GET_MISSION_RANKING_CALL),
    switchMap(({ payload = {} }) =>
      getMissionRanking(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getMissionRankingDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getMissionRankingFail({ error }));
        }),
      ),
    ),
  );

export default [
  getMissionsEpic,
  createMissionEpic,
  // getMissionEpic,
  updateMissionEpic,
  deleteMissionEpic,
  getMissionRankingEpic,
];
