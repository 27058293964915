import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { IDepartment } from 'src/types';
import { AnyAction } from 'redux';

const initialState = {
  departments: [] as IDepartment[],
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_DEPARTMENTS_CALL: {
      return state;
    }
    case types.GET_DEPARTMENTS_DONE: {
      const departments = payload.response.data.map((department: IDepartment) => camelizeKey(department));
      return update(state, {
        departments: { $set: departments },
      });
    }
    case types.GET_DEPARTMENTS_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
