import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getAttractions = payload => api.get(makePaginationUrl({ baseUrl: `/attractions`, ...payload }));

export const createAttraction = payload => api.post('/attraction', payload);

export const getAttraction = payload => api.get(`/attraction/${payload.attractionId}`);

export const updateAttraction = payload => api.put(`/attraction/${payload.attractionId}`, payload);

export const deleteAttraction = payload => api.delete(`/attraction/${payload.attractionId}`);
