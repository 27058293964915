import { request, oAuth2PasswordRequestForm } from './request';

const getApiUrl = () => {
  const isDevMode = window.location.hostname === 'localhost';
  if (isDevMode) {
    return 'https://skyfamily.cc/api';
    // return 'http://staging.skyfamily.cc/api';
    // return 'http://localhost:8000';
  } else {
    const apiUrl = `${window.location.origin}/api`;
    return apiUrl;
  }
};

const apiUrl = getApiUrl();

const getUrl = (url, baseUrl) => baseUrl.concat(url);

const ApiMethods = {
  get: (url, data, headers = {}, otherConfigs) =>
    request({
      method: 'GET',
      url: getUrl(url, apiUrl),
      data,
      headers,
      otherConfigs,
    }),
  post: (url, data, headers = {}, otherConfigs) =>
    request({
      method: 'POST',
      url: getUrl(url, apiUrl),
      data,
      headers,
      otherConfigs,
    }),
  delete: (url, data, headers = {}, otherConfigs) =>
    request({
      method: 'DELETE',
      url: getUrl(url, apiUrl),
      data,
      headers,
      otherConfigs,
    }),
  put: (url, data, headers = {}, otherConfigs) =>
    request({
      method: 'PUT',
      url: getUrl(url, apiUrl),
      data,
      headers,
      otherConfigs,
    }),
  patch: (url, data, headers = {}, otherConfigs) =>
    request({
      method: 'PATCH',
      url: getUrl(url, apiUrl),
      data,
      headers,
      otherConfigs,
    }),
  login: (url, data) =>
    oAuth2PasswordRequestForm({
      method: 'POST',
      url: getUrl(url, apiUrl),
      data,
    }),
};

export default ApiMethods;
