import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from './locales';

const Detector = {
  name: 'DetectorsName',
  async: true,
  // cacheUserLanguage: () => {},
};
const languageDetector = new LanguageDetector();
languageDetector.addDetector(Detector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'zh-TW',
    fallbackLng: 'zh-TW',
    ns: ['common'],
    defaultNS: ['common'],
    // eslint-disable-next-line no-undef
    // debug: process.env.NODE_ENV === 'development'
  });

export default i18n;
