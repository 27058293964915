/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError } from 'rxjs/operators';
import { toast } from 'react-toastify';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { uploadImage } from 'src/services/common';

const uploadImageEpic = action$ =>
  action$.pipe(
    ofType(types.UPLOAD_IMAGE_CALL),
    switchMap(({ payload = {} }) =>
      uploadImage(payload.data).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.uploadImageDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          toast.error(error.data.detail);
          if (onError) onError(error);
          return of(actions.uploadImageFail({ error }));
        }),
      ),
    ),
  );

export default [uploadImageEpic];
