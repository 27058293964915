import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const getDepartmentsCall = createAction(types.GET_DEPARTMENTS_CALL);
export const getDepartmentsDone = createAction(types.GET_DEPARTMENTS_DONE);
export const getDepartmentsFail = createAction(types.GET_DEPARTMENTS_FAIL);

export const createDepartmentCall = createAction(types.CREATE_DEPARTMENT_CALL);
export const createDepartmentDone = createAction(types.CREATE_DEPARTMENT_DONE);
export const createDepartmentFail = createAction(types.CREATE_DEPARTMENT_FAIL);

export const updateDepartmentCall = createAction(types.UPDATE_DEPARTMENT_CALL);
export const updateDepartmentDone = createAction(types.UPDATE_DEPARTMENT_DONE);
export const updateDepartmentFail = createAction(types.UPDATE_DEPARTMENT_FAIL);

export const deleteDepartmentCall = createAction(types.DELETE_DEPARTMENT_CALL);
export const deleteDepartmentDone = createAction(types.DELETE_DEPARTMENT_DONE);
export const deleteDepartmentFail = createAction(types.DELETE_DEPARTMENT_FAIL);
