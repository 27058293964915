export const INIT_EVENT_STATE = 'INIT_EVENT_STATE';

export const GET_EVENTS_CALL = 'GET_EVENTS_CALL';
export const GET_EVENTS_DONE = 'GET_EVENTS_DONE';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';

export const GET_EVENTS_IN_NEWCOMER_CALL = 'GET_EVENTS_IN_NEWCOMER_CALL';
export const GET_EVENTS_IN_NEWCOMER_DONE = 'GET_EVENTS_IN_NEWCOMER_DONE';
export const GET_EVENTS_IN_NEWCOMER_FAIL = 'GET_EVENTS_IN_NEWCOMER_FAIL';

export const GET_EVENTS_IN_ADMIN_CALL = 'GET_EVENTS_IN_ADMIN_CALL';
export const GET_EVENTS_IN_ADMIN_DONE = 'GET_EVENTS_IN_ADMIN_DONE';
export const GET_EVENTS_IN_ADMIN_FAIL = 'GET_EVENTS_IN_ADMIN_FAIL';

export const CREATE_EVENT_CALL = 'CREATE_EVENT_CALL';
export const CREATE_EVENT_DONE = 'CREATE_EVENT_DONE';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';

export const GET_EVENT_CALL = 'GET_EVENT_CALL';
export const GET_EVENT_DONE = 'GET_EVENT_DONE';
export const GET_EVENT_FAIL = 'GET_EVENT_FAIL';

export const UPDATE_EVENT_CALL = 'UPDATE_EVENT_CALL';
export const UPDATE_EVENT_DONE = 'UPDATE_EVENT_DONE';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';

export const DELETE_EVENT_CALL = 'DELETE_EVENT_CALL';
export const DELETE_EVENT_DONE = 'DELETE_EVENT_DONE';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';
