import * as types from 'src/store/types';
import { AnyAction } from 'redux';

import { camelizeKey } from 'src/utils/keyFormat';
import { IRole } from 'src/types';

const initialState = {
  roles: {
    data: [] as IRole[],
    page: 1,
    pageSize: 50,
    total: 0,
  },
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_ROLES_CALL: {
      return state;
    }
    case types.GET_ROLES_DONE: {
      const queryRoles = payload.response.data;
      return {
        ...state,
        roles: {
          ...queryRoles,
          data: queryRoles.data.map((role: IRole) => camelizeKey(role)),
        },
      };
    }
    case types.GET_ROLES_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
