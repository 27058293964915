import api from 'src/api';

export const getActivityOverview = payload => api.get(`/activity_overview?start_timestamp=${payload.startTimestamp}`);

export const getActivityByUser = payload => api.get(`/activity_by_user?start_timestamp=${payload.startTimestamp}&user_uuid=${payload.userId}`);

export const getUserAnalytics = () => api.get(`/user_analytics`);

export const getGenderAnalytics = () => api.get(`/gender_analytics`);

export const getAgeAnalytics = () => api.get(`/age_analytics`);

export const getWordsReadStatusByUser = payload =>
  api.get(`/words_read_status_by_user?start_timestamp=${payload.startTimestamp}&user_uuid=${payload.userId}`);
