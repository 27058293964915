import { IRole, IUser, IAllWorkspacesOfAUser } from 'src/types';
import { IChurch } from 'src/types/church';

interface UserState {
  token: string;
  profile: IUser;
  users: IUser[];
  churches: IChurch[];
  roles: IRole[];
  workspaces: IAllWorkspacesOfAUser[];
  clientIP: string;
}

export const selectUser = ({ userState }: { userState: UserState }): UserState => userState;
