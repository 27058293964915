import React from 'react';
import styled from 'styled-components';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import MediaImageCover from 'src/components/MediaImageCover';
import { removeHtmlTags } from 'src/utils/common';

const Wrapper = styled.div`
  padding: 0 12px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.palette.text.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${props => props.theme.palette.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CardContainer = styled.div<{
  $isActive: boolean;
}>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  background-color: ${props => (props.$isActive ? '#F0F7FF' : 'transparent')};
  &:hover {
    background-color: ${props => (props.$isActive ? '#F0F7FFaa' : '#EEE')};
  }
`;

interface IProps {
  isActive?: boolean;
  title: string;
  description?: string;
  youtubeUrl: string;
  onClick?: () => void;
  onRemove?: (event: React.MouseEvent) => void;
}

const HorizontalCard = (props: IProps) => {
  const { isActive = false, title, youtubeUrl, onClick, onRemove } = props;

  return (
    <CardContainer $isActive={isActive} role='presentation' onClick={onClick}>
      <Stack direction='row'>
        <MediaImageCover width={120} title={title} youtubeUrl={youtubeUrl} />
        <Wrapper>
          <Title>{title}</Title>
          <Description>{removeHtmlTags(props.description)}</Description>
        </Wrapper>
      </Stack>
      <IconButton aria-label='close' onClick={onRemove} style={{ flexShrink: 0, width: 32, height: 32 }}>
        <CloseOutlinedIcon fontSize='small' />
      </IconButton>
    </CardContainer>
  );
};

export default HorizontalCard;
