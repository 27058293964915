/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError, throttleTime } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { Translation } from 'react-i18next';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';
import {
  userLogin,
  getAllWorkspacesOfAUser,
  createUser,
  updateUser,
  getUsers,
  getUser,
  getUserFieldsOptions,
  getProfile,
  resetPasswordToDefault,
  changePassword,
  updateAvatar,
  updateUserWorkspace,
  getRegisterMeta,
  registerUser,
  updateCgmBarcode,
  updateNickname,
} from 'src/services/user';

const initEpic = action$ =>
  action$.pipe(
    ofType('init'),
    switchMap(() => of([])),
  );

const getAllWorkspacesOfAUserEpic = action$ =>
  action$.pipe(
    ofType(types.GET_ALL_WORKSPACE_OF_A_USER_CALL),
    switchMap(({ payload = {} }) =>
      getAllWorkspacesOfAUser().pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getAllWorkspacesOfAUserDone({ response }));
        }),
        catchError(error => of(actions.getAllWorkspacesOfAUserFail({ error }), actions.userLogoutCall())),
      ),
    ),
  );

const userLoginEpic = action$ =>
  action$.pipe(
    ofType(types.USER_LOGIN_CALL),
    switchMap(({ payload = {} }) =>
      userLogin(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.userLoginDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.userLoginFail({ error }));
        }),
      ),
    ),
  );

const getUsersEpic = action$ =>
  action$.pipe(
    ofType(types.GET_USERS_CALL),
    switchMap(({ payload = {} }) =>
      getUsers().pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess({ response });
          return of(actions.getUsersDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getUsersFail({ error }));
        }),
      ),
    ),
  );

const getUserEpic = action$ =>
  action$.pipe(
    ofType(types.GET_USER_CALL),
    switchMap(({ payload = {} }) =>
      getUser(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getUserDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getUserFail({ error }));
        }),
      ),
    ),
  );

const getProfileEpic = action$ =>
  action$.pipe(
    ofType(types.GET_PROFILE_CALL),
    switchMap(({ payload = {} }) =>
      getProfile(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getProfileDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getProfileFail({ error }));
        }),
      ),
    ),
  );

const createUserEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_USER_CALL),
    switchMap(({ payload = {} }) =>
      createUser(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createUserDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createUserFail({ error }));
        }),
      ),
    ),
  );

const updateUserEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_USER_CALL),
    switchMap(({ payload = {} }) =>
      updateUser(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.updateUserDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateUserFail({ error }));
        }),
      ),
    ),
  );

const getUserFieldsOptionsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_USER_FIELDS_OPTIONS_CALL),
    switchMap(({ payload = {} }) =>
      getUserFieldsOptions(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getUserFieldsOptionsDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getUserFieldsOptionsFail({ error }));
        }),
      ),
    ),
  );

const userLogoutEpic = action$ =>
  action$.pipe(
    ofType(types.USER_LOGOUT_CALL),
    throttleTime(1000),
    switchMap(() => {
      toast.success(<Translation>{t => t('toast_message:logout_success')}</Translation>);
      return of(actions.userLogoutDone());
    }),
  );

const resetPasswordToDefaultEpic = action$ =>
  action$.pipe(
    ofType(types.RESET_PASSWORD_TO_DEFAULT_CALL),
    switchMap(({ payload = {} }) =>
      resetPasswordToDefault(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.resetPasswordToDefaultDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.resetPasswordToDefaultFail({ error }));
        }),
      ),
    ),
  );

const changePasswordEpic = action$ =>
  action$.pipe(
    ofType(types.CHANGE_PASSWORD_CALL),
    switchMap(({ payload = {} }) =>
      changePassword(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.changePasswordDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.changePasswordFail({ error }));
        }),
      ),
    ),
  );

const updateAvatarEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_AVATAR_CALL),
    switchMap(({ payload = {} }) =>
      updateAvatar(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateAvatarDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateAvatarFail({ error }));
        }),
      ),
    ),
  );

const updateUserWorkspaceEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_USER_WORKSPACE_CALL),
    switchMap(({ payload = {} }) =>
      updateUserWorkspace(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateUserWorkspaceDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateUserWorkspaceFail({ error }));
        }),
      ),
    ),
  );

const getRegisterMetaEpic = action$ =>
  action$.pipe(
    ofType(types.GET_REGISTER_META_CALL),
    switchMap(({ payload = {} }) =>
      getRegisterMeta(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getRegisterMetaDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getRegisterMetaFail({ error }));
        }),
      ),
    ),
  );

const registerUserEpic = action$ =>
  action$.pipe(
    ofType(types.REGISTER_USER_CALL),
    switchMap(({ payload = {} }) =>
      registerUser(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.registerUserDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.registerUserFail({ error }));
        }),
      ),
    ),
  );

const updateCgmBarcodeEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_CGM_BARCODE_CALL),
    switchMap(({ payload = {} }) =>
      updateCgmBarcode(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.updateCgmBarcodeDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateCgmBarcodeFail({ error }));
        }),
      ),
    ),
  );

const updateNicknameEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_NICKNAME_CALL),
    switchMap(({ payload = {} }) =>
      updateNickname(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.updateNicknameDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateNicknameFail({ error }));
        }),
      ),
    ),
  );

export default [
  initEpic,
  getAllWorkspacesOfAUserEpic,
  userLoginEpic,
  userLogoutEpic,
  createUserEpic,
  updateUserEpic,
  getUsersEpic,
  getUserEpic,
  getUserFieldsOptionsEpic,
  getProfileEpic,
  resetPasswordToDefaultEpic,
  changePasswordEpic,
  updateAvatarEpic,
  updateUserWorkspaceEpic,
  getRegisterMetaEpic,
  registerUserEpic,
  updateCgmBarcodeEpic,
  updateNicknameEpic,
];
