/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { mapKeys, snakeCase } from 'lodash';

export const camelizeKey = (obj: any): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => camelizeKey(item));
  }

  const camelObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = key.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
      camelObj[camelKey] = camelizeKey(obj[key]);
    }
  }
  return camelObj;
};

export const snakeizeKey = (object: any): any => mapKeys(object, (v, k) => snakeCase(k));
