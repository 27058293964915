import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getArticles = payload => api.get(makePaginationUrl({ baseUrl: `/articles`, ...payload }));

export const createArticle = payload => api.post('/article', payload);

export const getArticle = ({ articleId }) => api.get(`/article/${articleId}`);

export const updateArticle = payload => api.put(`/article/${payload.articleId}`, payload);

export const deleteArticle = payload => api.delete(`/article/${payload.articleId}`);
