import api from 'src/api';

export const getAllWorkspacesOfAUser = () => api.get('/all_workspaces_of_a_user');

export const userLogin = ({ formData }) => api.login('/login', formData);

export const getUsers = () => api.get('/users');

export const getProfile = () => api.get('/profile');

export const getUser = payload => api.get(`/user/${payload.userId}`);

export const createUser = payload => api.post('/user', payload);

export const updateUser = payload => api.put(`/user/${payload.userId}`, payload);

export const getUserFieldsOptions = () => api.get('/user_fields_options');

export const resetPasswordToDefault = payload => api.post('/reset_password_to_default', payload);

export const changePassword = payload => api.post('/change_password', payload);

export const updateAvatar = payload => api.post(`/avatar`, payload.formData);

export const updateUserWorkspace = payload => api.put('/user_workspace', payload);

export const getRegisterMeta = payload => api.get(`/register_meta/${payload.workspaceUid}`);

export const registerUser = payload => api.post('/register', payload);

export const updateCgmBarcode = payload => api.post('/cgm_barcode', payload);

export const updateNickname = ({ userId, ...payload }) => api.put(`/user/${userId}/nickname`, payload);
