import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

const LoadingSideMenu = () => {
  return (
    <List>
      <ListItem>
        <Skeleton variant='circular' width={40} height={40} />
        <div>
          <Skeleton variant='text' sx={{ fontSize: '1rem', width: '70px', marginLeft: 2 }} />
          <Skeleton variant='text' sx={{ fontSize: '1rem', width: '140px', marginLeft: 2 }} />
        </div>
      </ListItem>
      <Divider />
      <ListItem>
        <Skeleton variant='rounded' width={24} height={24} />
        <Skeleton variant='text' sx={{ fontSize: '1rem', width: '40px', marginLeft: 4 }} />
      </ListItem>
      <ListItem>
        <Skeleton variant='rounded' width={24} height={24} />
        <Skeleton variant='text' sx={{ fontSize: '1rem', width: '40px', marginLeft: 4 }} />
      </ListItem>
      <ListItem>
        <Skeleton variant='rounded' width={24} height={24} />
        <Skeleton variant='text' sx={{ fontSize: '1rem', width: '40px', marginLeft: 4 }} />
      </ListItem>
    </List>
  );
};

export default LoadingSideMenu;
