/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, catchError, concatMap } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import {
  getWords,
  getUnreadWords,
  getWord,
  createWord,
  updateWord,
  deleteWord,
  getWordsInAdmin,
  getWordInAdmin,
  getWordsInNewcomer,
  getWordInNewcomer,
} from 'src/services/word';

const getWordsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_WORDS_CALL),
    concatMap(({ payload = {} }) =>
      getWords(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getWordsDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getWordsFail({ error }));
        }),
      ),
    ),
  );

const getUnreadWordsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_UNREAD_WORDS_CALL),
    concatMap(({ payload = {} }) =>
      getUnreadWords(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getUnreadWordsDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getUnreadWordsFail({ error }));
        }),
      ),
    ),
  );

const getWordEpic = action$ =>
  action$.pipe(
    ofType(types.GET_WORD_CALL),
    switchMap(({ payload = {} }) =>
      getWord(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getWordDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getWordFail({ error }));
        }),
      ),
    ),
  );

const createWordEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_WORD_CALL),
    switchMap(({ payload = {} }) =>
      createWord(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createWordDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createWordFail({ error }));
        }),
      ),
    ),
  );

const updateWordEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_WORD_CALL),
    switchMap(({ payload = {} }) =>
      updateWord(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.updateWordDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateWordFail({ error }));
        }),
      ),
    ),
  );

const deleteWordEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_WORD_CALL),
    switchMap(({ payload = {} }) =>
      deleteWord(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.deleteWordDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteWordFail({ error }));
        }),
      ),
    ),
  );

const getWordsInAdminEpic = action$ =>
  action$.pipe(
    ofType(types.GET_WORDS_IN_ADMIN_CALL),
    concatMap(({ payload = {} }) =>
      getWordsInAdmin(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getWordsInAdminDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getWordsInAdminFail({ error }));
        }),
      ),
    ),
  );

const getWordInAdminEpic = action$ =>
  action$.pipe(
    ofType(types.GET_WORD_IN_ADMIN_CALL),
    switchMap(({ payload = {} }) =>
      getWordInAdmin(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getWordInAdminDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getWordInAdminFail({ error }));
        }),
      ),
    ),
  );

const getWordsInNewcomerEpic = action$ =>
  action$.pipe(
    ofType(types.GET_WORDS_IN_NEWCOMER_CALL),
    concatMap(({ payload = {} }) =>
      getWordsInNewcomer(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getWordsInNewcomerDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getWordsInNewcomerFail({ error }));
        }),
      ),
    ),
  );

const getWordInNewcomerEpic = action$ =>
  action$.pipe(
    ofType(types.GET_WORD_IN_NEWCOMER_CALL),
    switchMap(({ payload = {} }) =>
      getWordInNewcomer(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getWordInNewcomerDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getWordInNewcomerFail({ error }));
        }),
      ),
    ),
  );

export default [
  getWordsEpic,
  getUnreadWordsEpic,
  getWordEpic,
  createWordEpic,
  updateWordEpic,
  deleteWordEpic,
  getWordsInAdminEpic,
  getWordInAdminEpic,
  getWordsInNewcomerEpic,
  getWordInNewcomerEpic,
];
