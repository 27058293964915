import dayjs from 'dayjs';

import * as types from 'src/store/types';
import update from 'immutability-helper';
import { camelizeKey } from 'src/utils/keyFormat';
import { ILecture } from 'src/types';
import { AnyAction } from 'redux';

const timezoneOffset = dayjs().utcOffset();

const initialState = {
  paginatedLectures: {
    data: [] as ILecture[],
    page: 1,
    pageSize: 10,
    total: 0,
  },
};

const reducer = (state = initialState, action: AnyAction): typeof initialState => {
  const { type, payload } = action;

  switch (type) {
    case types.INIT_LECTURE_STATE: {
      return initialState;
    }
    case types.GET_LECTURES_CALL: {
      return state;
    }
    case types.GET_LECTURES_DONE: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paginatedLectures = payload.response.data;
      const updatedPaginatedLectures = camelizeKey({
        ...paginatedLectures,
        data: paginatedLectures?.data
          ?.map((lecture: ILecture[]) => camelizeKey(lecture))
          .map((lecture: ILecture) => ({
            ...lecture,
            publishAt: dayjs(lecture.publishAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            createdAt: dayjs(lecture.createdAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(lecture.updatedAt).add(timezoneOffset, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          }))
          .sort((a: ILecture, b: ILecture) => dayjs(b.publishAt).diff(dayjs(a.publishAt))),
      });
      return update(state, {
        paginatedLectures: { $set: updatedPaginatedLectures },
      });
    }
    case types.GET_LECTURES_FAIL: {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
