import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initMusicState = createAction(types.INIT_MUSIC_STATE);

export const getMusicsCall = createAction(types.GET_MUSICS_CALL);
export const getMusicsDone = createAction(types.GET_MUSICS_DONE);
export const getMusicsFail = createAction(types.GET_MUSICS_FAIL);

export const createMusicCall = createAction(types.CREATE_MUSIC_CALL);
export const createMusicDone = createAction(types.CREATE_MUSIC_DONE);
export const createMusicFail = createAction(types.CREATE_MUSIC_FAIL);

export const getMusicCall = createAction(types.GET_MUSIC_CALL);
export const getMusicDone = createAction(types.GET_MUSIC_DONE);
export const getMusicFail = createAction(types.GET_MUSIC_FAIL);

export const updateMusicCall = createAction(types.UPDATE_MUSIC_CALL);
export const updateMusicDone = createAction(types.UPDATE_MUSIC_DONE);
export const updateMusicFail = createAction(types.UPDATE_MUSIC_FAIL);

export const deleteMusicCall = createAction(types.DELETE_MUSIC_CALL);
export const deleteMusicDone = createAction(types.DELETE_MUSIC_DONE);
export const deleteMusicFail = createAction(types.DELETE_MUSIC_FAIL);

export const getMusicTagsCall = createAction(types.GET_MUSIC_TAGS_CALL);
export const getMusicTagsDone = createAction(types.GET_MUSIC_TAGS_DONE);
export const getMusicTagsFail = createAction(types.GET_MUSIC_TAGS_FAIL);
