import * as React from 'react';
import styled from 'styled-components/macro';

import Avatar from '@mui/material/Avatar';
import { ListItemButton, ListItemIcon, ListItemText, ListItemAvatar } from '@mui/material';

const EllipsisListItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  & > div {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 160px;
  }
`;

interface IItem {
  Icon: React.ElementType;
  title: string;
  routePath?: string;
  isActive?: boolean;
  handleClickItem: (routePath: string) => void;
}

export const Item = ({ Icon, title, routePath, isActive = false, handleClickItem }: IItem): JSX.Element => {
  const handleChangeRoute = React.useCallback(() => {
    handleClickItem(routePath);
  }, []);

  return (
    <ListItemButton selected={isActive} onClick={handleChangeRoute}>
      <ListItemIcon>
        <Icon width={24} height={24} />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

interface IProfileItem {
  name: string;
  email: string;
  gender: string;
  avatarUrl: string;
  routePath?: string;
  handleClickItem: (routePath: string) => void;
}

export const ProfileItem = ({ name, email, gender, avatarUrl, routePath, handleClickItem }: IProfileItem): JSX.Element => {
  const handleChangeRoute = React.useCallback(() => {
    handleClickItem(routePath);
  }, []);

  return (
    <ListItemButton onClick={handleChangeRoute}>
      <ListItemAvatar>
        <Avatar alt={name} src={avatarUrl} sx={{ backgroundColor: gender === 'male' ? '#6FD3E9' : '#E9A1D4' }} />
      </ListItemAvatar>
      <EllipsisListItemText>
        <div>{name}</div>
        <div>{email}</div>
      </EllipsisListItemText>
    </ListItemButton>
  );
};

export default {
  Item,
  ProfileItem,
};
