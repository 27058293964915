import * as React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';

import * as actions from 'src/store/actions';
import * as selectors from 'src/store/selectors';
import { BOTTOM_NAVIGATION_BAR_HEIGHT, GLOBAL_MEDIA_PLAYER_HEIGHT, NAVIGATION_BAR_HEIGHT } from 'src/theme';
import { useBottomNav } from 'src/hooks/useBottomNav';

import Control from './Control';
import Playlist from './Playlist';

const StyledDialog = styled.div<{
  $open: boolean;
  $controlHeight: number;
  $bottomNavHeight: number;
}>`
  position: absolute;
  z-index: 20;
  background: #fff;
  height: calc(100vh - ${props => props.$controlHeight}px - ${props => props.$bottomNavHeight}px - ${NAVIGATION_BAR_HEIGHT}px);
  width: 100vw;
  transform: translateY(${({ $open }) => ($open ? '-100%' : '0%')});
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  border-radius: 16px 16px 0px 0px;
`;

const Mask = styled.div<{
  $open: boolean;
}>`
  position: absolute;
  z-index: 400;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  top: 0px;
  opacity: ${props => (props.$open ? 1 : 0)};
  pointer-events: none;
  transition: all 0.3s ease-in-out;
`;

const GlobalMediaPlayer = () => {
  const dispatch = useDispatch();
  const { playlist, openPlaylist } = useSelector(selectors.selectGlobalMediaPlayerState);
  const [showControls, setShowControls] = React.useState(false);
  const [progress, setProgress] = React.useState<number>(0);
  const { showBottomNav } = useBottomNav();
  const showControl = playlist.length > 0;

  const handleSetOpenPlaylist = (isOpen: boolean) => {
    dispatch(
      actions.globalPlayerSetOpenPlaylist({
        openPlaylist: isOpen,
      }),
    );
  };

  React.useEffect(() => {
    if (playlist.length === 0) {
      setTimeout(() => {
        setShowControls(false);
      }, 500);
    }
    if (playlist.length > 0) {
      setShowControls(true);
    }
  }, [playlist.length]);

  return showControls ? (
    <>
      <StyledDialog
        $open={openPlaylist}
        $controlHeight={showControl ? GLOBAL_MEDIA_PLAYER_HEIGHT : 0}
        $bottomNavHeight={showBottomNav ? BOTTOM_NAVIGATION_BAR_HEIGHT : 0}
      >
        <Playlist
          handleOnProgress={({ currentTime, duration }: { currentTime: number; duration: number }) => setProgress((currentTime / duration) * 100)}
        />
      </StyledDialog>
      {createPortal(<Mask $open={openPlaylist} onClick={() => handleSetOpenPlaylist(false)} />, document.getElementById('root') as HTMLElement)}
      <Control progress={progress} handleTogglePlaylist={() => handleSetOpenPlaylist(!openPlaylist)} />
    </>
  ) : (
    <></>
  );
};

export default GlobalMediaPlayer;
