export const INIT_MUSIC_STATE = 'INIT_MUSIC_STATE';

export const GET_MUSICS_CALL = 'GET_MUSICS_CALL';
export const GET_MUSICS_DONE = 'GET_MUSICS_DONE';
export const GET_MUSICS_FAIL = 'GET_MUSICS_FAIL';

export const CREATE_MUSIC_CALL = 'CREATE_MUSIC_CALL';
export const CREATE_MUSIC_DONE = 'CREATE_MUSIC_DONE';
export const CREATE_MUSIC_FAIL = 'CREATE_MUSIC_FAIL';

export const GET_MUSIC_CALL = 'GET_MUSIC_CALL';
export const GET_MUSIC_DONE = 'GET_MUSIC_DONE';
export const GET_MUSIC_FAIL = 'GET_MUSIC_FAIL';

export const UPDATE_MUSIC_CALL = 'UPDATE_MUSIC_CALL';
export const UPDATE_MUSIC_DONE = 'UPDATE_MUSIC_DONE';
export const UPDATE_MUSIC_FAIL = 'UPDATE_MUSIC_FAIL';

export const DELETE_MUSIC_CALL = 'DELETE_MUSIC_CALL';
export const DELETE_MUSIC_DONE = 'DELETE_MUSIC_DONE';
export const DELETE_MUSIC_FAIL = 'DELETE_MUSIC_FAIL';

export const GET_MUSIC_TAGS_CALL = 'GET_MUSIC_TAGS_CALL';
export const GET_MUSIC_TAGS_DONE = 'GET_MUSIC_TAGS_DONE';
export const GET_MUSIC_TAGS_FAIL = 'GET_MUSIC_TAGS_FAIL';
