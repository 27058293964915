import { useSelector } from 'react-redux';

import * as selectors from 'src/store/selectors';

export const useAccessControl = () => {
  const { profile } = useSelector(selectors.selectUser);
  const { roles, accountStatus } = profile || {};
  const hasAccessRight = ({ functionName }: { functionName: string }) => {
    if (['home', 'logout'].indexOf(functionName) > -1) {
      return true;
    }
    if (accountStatus !== 'verified') {
      return false;
    }
    const functionTags = roles.flatMap(role => role.functionTags);
    return functionTags.includes(functionName);
  };
  return {
    hasAccessRight,
  };
};
