import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getWords = payload => api.get(makePaginationUrl({ baseUrl: '/words', ...payload }));

export const getWord = payload => api.get(`/word/${payload.wordId}`);

export const createWord = payload => api.post('/word', payload);

export const updateWord = payload => api.put(`/word/${payload.wordId}`, payload);

export const getUnreadWords = payload => api.get(makePaginationUrl({ baseUrl: '/unread_words', ...payload }));

export const deleteWord = payload => api.delete(`/word/${payload.wordId}`);

// Get words in admin
export const getWordsInAdmin = payload => api.get(makePaginationUrl({ baseUrl: '/words_in_admin', ...payload }));

export const getWordInAdmin = payload => api.get(`/words_in_admin/${payload.wordId}`);

// Get words in newcomer
export const getWordsInNewcomer = payload => api.get(makePaginationUrl({ baseUrl: '/words_in_newcomer', ...payload }));

export const getWordInNewcomer = payload => api.get(`/words_in_newcomer/${payload.wordId}`);
