import api from 'src/api';
import { makePaginationUrl } from 'src/utils/common';

export const getLectures = payload => api.get(makePaginationUrl({ baseUrl: `/lectures`, ...payload }));

export const createLecture = payload => api.post('/lecture', payload);

export const getLecture = payload => api.get(`/lecture/${payload.lectureId}`);

export const updateLecture = payload => api.put(`/lecture/${payload.lectureId}`, payload);

export const deleteLecture = payload => api.delete(`/lecture/${payload.lectureId}`);
