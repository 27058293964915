/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, EMPTY } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, concatMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import { getAttractions, createAttraction, getAttraction, updateAttraction, deleteAttraction } from 'src/services/attraction';

const getAttractionsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_ATTRACTIONS_CALL),
    concatMap(({ payload = {} }) =>
      getAttractions(payload).pipe(
        switchMap(response => {
          const { onSuccess, toReducer = true } = payload;
          if (onSuccess) onSuccess(response);
          if (toReducer) {
            return of(actions.getAttractionsDone({ response }));
          } else {
            return EMPTY;
          }
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getAttractionsFail({ error }));
        }),
      ),
    ),
  );

const createAttractionEpic = action$ =>
  action$.pipe(
    ofType(types.CREATE_ATTRACTION_CALL),
    switchMap(({ payload = {} }) =>
      createAttraction(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess();
          return of(actions.createAttractionDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.createAttractionFail({ error }));
        }),
      ),
    ),
  );

const getAttractionEpic = action$ =>
  action$.pipe(
    ofType(types.GET_ATTRACTION_CALL),
    switchMap(({ payload = {} }) =>
      getAttraction(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getAttractionDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getAttractionFail({ error }));
        }),
      ),
    ),
  );

const updateAttractionEpic = action$ =>
  action$.pipe(
    ofType(types.UPDATE_ATTRACTION_CALL),
    switchMap(({ payload = {} }) =>
      updateAttraction(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.updateAttractionDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.updateAttractionFail({ error }));
        }),
      ),
    ),
  );

const deleteAttractionEpic = action$ =>
  action$.pipe(
    ofType(types.DELETE_ATTRACTION_CALL),
    switchMap(({ payload = {} }) =>
      deleteAttraction(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.deleteAttractionDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.deleteAttractionFail({ error }));
        }),
      ),
    ),
  );

export default [getAttractionsEpic, createAttractionEpic, getAttractionEpic, updateAttractionEpic, deleteAttractionEpic];
