import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import * as selectors from 'src/store/selectors';
import * as actions from 'src/store/actions';
import { routePathConfig } from 'src/routes';
import { getWorkspace } from 'src/storage/workspaceStorage';

export const withAuthControl = WrappedComponent => {
  const Component = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();
    const { profile, token } = useSelector(selectors.selectUser);
    const { activeWorkspace } = useSelector(selectors.selectWorkspace);
    const hasWorkspaceToken = !!activeWorkspace?.uuid || !!getWorkspace();
    const hasToken = !!token;
    const isLoginPage = pathname === routePathConfig.login;
    const isRegiserPage = pathname.includes('/register/');
    const isWorkspacePage = pathname === routePathConfig.workspace;

    React.useEffect(() => {
      if (hasToken && !isWorkspacePage) {
        dispatch(
          actions.getAllWorkspacesOfAUserCall({
            onSuccess: response => {
              const workspaces = response.data.workspaces;
              const hasOnlyOneWorkspace = workspaces.length === 1;
              const hasWorkspace = workspaces.length > 0;
              if (!hasWorkspace) {
                // 如果使用者沒有擁有任何一個 workspace，則直接登出
                dispatch(actions.userLogoutCall());
                return;
              }
              if (hasOnlyOneWorkspace) {
                // 如果使用者只有一個 workspace，則直接進入此 workspace
                dispatch(actions.setActiveWorkspace({ workspace: workspaces[0] }));
                return;
              }
              const foundActiveWorkspace = workspaces.find(workspace => workspace.uuid === getWorkspace());
              if (foundActiveWorkspace) {
                // 如果存在 localStorage 的 workspace 是合法的，則直接進入此 workspace
                dispatch(actions.setActiveWorkspace({ workspace: foundActiveWorkspace }));
              }
            },
          }),
        );
      }
    }, [hasToken, isWorkspacePage]);

    React.useEffect(() => {
      // 取得 user profile
      if (hasWorkspaceToken && hasToken && isEmpty(profile)) {
        dispatch(actions.getProfileCall());
      }
    }, [hasWorkspaceToken, hasToken, profile]);

    React.useEffect(() => {
      if (!hasToken && !isLoginPage && !isRegiserPage) {
        navigate(routePathConfig.login);
        return;
      }
      if (hasToken && !hasWorkspaceToken && !isWorkspacePage) {
        navigate(routePathConfig.workspace);
        return;
      }
    }, [hasToken, hasWorkspaceToken, pathname]);

    React.useEffect(() => {
      if (profile.accountStatus === 'freeze') {
        dispatch(actions.userLogoutCall());
      }
    }, [profile.accountStatus]);

    return <WrappedComponent {...props} />;
  };
  return Component;
};
