import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initArticleState = createAction(types.INIT_ARTICLE_STATE);

export const getArticlesCall = createAction(types.GET_ARTICLES_CALL);
export const getArticlesDone = createAction(types.GET_ARTICLES_DONE);
export const getArticlesFail = createAction(types.GET_ARTICLES_FAIL);

export const createArticleCall = createAction(types.CREATE_ARTICLE_CALL);
export const createArticleDone = createAction(types.CREATE_ARTICLE_DONE);
export const createArticleFail = createAction(types.CREATE_ARTICLE_FAIL);

export const getArticleCall = createAction(types.GET_ARTICLE_CALL);
export const getArticleDone = createAction(types.GET_ARTICLE_DONE);
export const getArticleFail = createAction(types.GET_ARTICLE_FAIL);

export const updateArticleCall = createAction(types.UPDATE_ARTICLE_CALL);
export const updateArticleDone = createAction(types.UPDATE_ARTICLE_DONE);
export const updateArticleFail = createAction(types.UPDATE_ARTICLE_FAIL);

export const deleteArticleCall = createAction(types.DELETE_ARTICLE_CALL);
export const deleteArticleDone = createAction(types.DELETE_ARTICLE_DONE);
export const deleteArticleFail = createAction(types.DELETE_ARTICLE_FAIL);
