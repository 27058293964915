import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QueueMusicOutlinedIcon from '@mui/icons-material/QueueMusicOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LinearProgress from '@mui/material/LinearProgress';

import * as actions from 'src/store/actions';
import * as selectors from 'src/store/selectors';
import { device } from 'src/theme/breakpoints';
import { removeHtmlTags } from 'src/utils/common';

const StyledProgress = styled(LinearProgress)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
`;

const SmallIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Container = styled.div`
  box-shadow: 1px 2px 8px #00000029;
  padding: 6px 12px;
  position: relative;
  z-index: 50;
  background: #fff;
`;

const EllipsisText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 300px;
  @media ${device.mobile} {
    max-width: 160px;
  }
`;

const Name = styled(EllipsisText)`
  font-size: 14px;
  font-weight: 700;
`;

const Description = styled(EllipsisText)`
  font-size: 14px;
  color: ${props => props.theme.palette.text.secondary};
`;

const Img = styled.img`
  width: 64px;
  height: 36px;
  object-fit: cover;
  margin-right: 8px;
  @media ${device.mobile} {
    display: none;
  }
`;

interface IProps {
  progress: number;
  handleTogglePlaylist?: () => void;
}

const Control = (props: IProps) => {
  const dispatch = useDispatch();
  const { play, playlist = [], currentPlayUID } = useSelector(selectors.selectGlobalMediaPlayerState);
  const { progress, handleTogglePlaylist } = props;
  const currentPlay = playlist.find(item => item.uuid === currentPlayUID);
  const nextPlayUID =
    playlist[playlist.length - 1]?.uuid === currentPlayUID
      ? playlist[0]?.uuid
      : playlist[playlist.findIndex(item => item.uuid === currentPlayUID) + 1]?.uuid;
  const prevPlayUID =
    playlist[0]?.uuid === currentPlayUID
      ? playlist[playlist.length - 1]?.uuid
      : playlist[playlist.findIndex(item => item.uuid === currentPlayUID) - 1]?.uuid;

  const handleClosePlayer = () => {
    dispatch(actions.globalPlayerInitState());
  };

  const handleClickItem = (uuid: string) => {
    dispatch(
      actions.globalPlayerSetCurrentPlayUID({
        uuid,
      }),
    );
  };

  const handleClickPlay = () => {
    if (!currentPlayUID) {
      handleClickItem(playlist[0]?.uuid);
    }
    dispatch(
      actions.globalPlayerSetPlay({
        play: !play,
      }),
    );
  };

  return (
    <Container className='global-media-player__control'>
      <StyledProgress variant='determinate' value={progress} />
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center'>
          <SmallIconButton onClick={() => handleClickItem(prevPlayUID)}>
            <SkipPreviousIcon />
          </SmallIconButton>
          <IconButton onClick={handleClickPlay}>{play ? <PauseOutlinedIcon /> : <PlayArrowIcon />}</IconButton>
          <SmallIconButton onClick={() => handleClickItem(nextPlayUID)}>
            <SkipNextIcon />
          </SmallIconButton>
        </Stack>
        <Stack sx={{ cursor: 'pointer' }} direction='row' alignItems='center' spacing={1} onClick={handleTogglePlaylist}>
          {currentPlay?.coverImageUrl && <Img src={currentPlay?.coverImageUrl} />}
          <Stack>
            <Name>{currentPlay?.name || '按下播放鍵開始播放'}</Name>
            <Description>{removeHtmlTags(currentPlay?.description)}</Description>
          </Stack>
        </Stack>
        <Stack direction='row' alignItems='center' spacing={2}>
          <SmallIconButton onClick={handleTogglePlaylist}>
            <Badge badgeContent={playlist.length} color='primary'>
              <QueueMusicOutlinedIcon />
            </Badge>
          </SmallIconButton>
          <SmallIconButton onClick={handleClosePlayer}>
            <CloseOutlinedIcon />
          </SmallIconButton>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Control;
