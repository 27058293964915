export const INIT_MISSION_STATE = 'INIT_MISSION_STATE';

export const GET_MISSIONS_CALL = 'GET_MISSIONS_CALL';
export const GET_MISSIONS_DONE = 'GET_MISSIONS_DONE';
export const GET_MISSIONS_FAIL = 'GET_MISSIONS_FAIL';

export const CREATE_MISSION_CALL = 'CREATE_MISSION_CALL';
export const CREATE_MISSION_DONE = 'CREATE_MISSION_DONE';
export const CREATE_MISSION_FAIL = 'CREATE_MISSION_FAIL';

// export const GET_MISSION_CALL = 'GET_MISSION_CALL';
// export const GET_MISSION_DONE = 'GET_MISSION_DONE';
// export const GET_MISSION_FAIL = 'GET_MISSION_FAIL';

export const UPDATE_MISSION_CALL = 'UPDATE_MISSION_CALL';
export const UPDATE_MISSION_DONE = 'UPDATE_MISSION_DONE';
export const UPDATE_MISSION_FAIL = 'UPDATE_MISSION_FAIL';

export const DELETE_MISSION_CALL = 'DELETE_MISSION_CALL';
export const DELETE_MISSION_DONE = 'DELETE_MISSION_DONE';
export const DELETE_MISSION_FAIL = 'DELETE_MISSION_FAIL';

export const GET_MISSION_RANKING_CALL = 'GET_MISSION_RANKING_CALL';
export const GET_MISSION_RANKING_DONE = 'GET_MISSION_RANKING_DONE';
export const GET_MISSION_RANKING_FAIL = 'GET_MISSION_RANKING_FAIL';
