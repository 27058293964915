import * as React from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as selectors from 'src/store/selectors';

const env = process.env.REACT_APP_ENV;

export const useGATracking = () => {
  const location = useLocation();
  const { profile } = useSelector(selectors.selectUser);
  const username = `${profile?.familyName}${profile?.givenName}`;

  React.useEffect(() => {
    if (env && profile) {
      ReactGA.set({ user: `${username}_${profile?.email}` });
    }
    if (env) {
      ReactGA.send({ hitType: 'page_view', page: location.pathname + location.search });
    }
  }, [location.pathname, username]);
};

export const useGAInit = () => {
  React.useEffect(() => {
    if (env === 'PRODUCTION') {
      ReactGA.initialize('G-FTC82XY2WJ');
    }
    if (env === 'STAGING') {
      ReactGA.initialize('G-LBXJT9W9CF');
    }
    if (env) {
      ReactGA.send({ hitType: 'page_view', page: window.location.href });
    }
  }, []);
};
