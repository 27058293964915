import { IAllWorkspacesOfAUser, IWorkspace } from 'src/types';

interface WorkspaceState {
  activeWorkspace: IAllWorkspacesOfAUser;
  paginatedWorkspaces: {
    data: IWorkspace[];
    page: number;
    pageSize: number;
    total: number;
  };
}

export const selectWorkspace = ({ workspaceState }: { workspaceState: WorkspaceState }): WorkspaceState => workspaceState;
