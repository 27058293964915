/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap, concatMap, catchError } from 'rxjs/operators';

import * as types from 'src/store/types';
import * as actions from 'src/store/actions';

import {
  getActivityOverview,
  getActivityByUser,
  getUserAnalytics,
  getGenderAnalytics,
  getAgeAnalytics,
  getWordsReadStatusByUser,
} from 'src/services/analytics';

const getActivityOverviewEpic = action$ =>
  action$.pipe(
    ofType(types.GET_ACTIVITY_OVERVIEW_CALL),
    switchMap(({ payload = {} }) =>
      getActivityOverview(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getActivityOverviewDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getActivityOverviewFail({ error }));
        }),
      ),
    ),
  );

const getActivityByUserEpic = action$ =>
  action$.pipe(
    ofType(types.GET_ACTIVITY_BY_USER_CALL),
    concatMap(({ payload = {} }) =>
      getActivityByUser(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getActivityByUserDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getActivityByUserFail({ error }));
        }),
      ),
    ),
  );

const getUserAnalyticsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_USER_ANALYTICS_CALL),
    switchMap(({ payload = {} }) =>
      getUserAnalytics(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getUserAnalyticsDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getUserAnalyticsFail({ error }));
        }),
      ),
    ),
  );

const getGenderAnalyticsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_GENDER_ANALYTICS_CALL),
    switchMap(({ payload = {} }) =>
      getGenderAnalytics(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getGenderAnalyticsDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getGenderAnalyticsFail({ error }));
        }),
      ),
    ),
  );

const getAgeAnalyticsEpic = action$ =>
  action$.pipe(
    ofType(types.GET_AGE_ANALYTICS_CALL),
    switchMap(({ payload = {} }) =>
      getAgeAnalytics(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getAgeAnalyticsDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getAgeAnalyticsFail({ error }));
        }),
      ),
    ),
  );

const getWordsReadStatusByUserEpic = action$ =>
  action$.pipe(
    ofType(types.GET_WORDS_READ_STATUS_BY_USER_CALL),
    concatMap(({ payload = {} }) =>
      getWordsReadStatusByUser(payload).pipe(
        switchMap(response => {
          const { onSuccess } = payload;
          if (onSuccess) onSuccess(response);
          return of(actions.getWordsReadStatusByUserDone({ response }));
        }),
        catchError(error => {
          const { onError } = payload;
          if (onError) onError(error);
          return of(actions.getWordsReadStatusByUserFail({ error }));
        }),
      ),
    ),
  );

export default [
  getActivityOverviewEpic,
  getUserAnalyticsEpic,
  getGenderAnalyticsEpic,
  getAgeAnalyticsEpic,
  getActivityByUserEpic,
  getWordsReadStatusByUserEpic,
];
