import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const getActivityOverviewCall = createAction(types.GET_ACTIVITY_OVERVIEW_CALL);
export const getActivityOverviewDone = createAction(types.GET_ACTIVITY_OVERVIEW_DONE);
export const getActivityOverviewFail = createAction(types.GET_ACTIVITY_OVERVIEW_FAIL);

export const getActivityByUserCall = createAction(types.GET_ACTIVITY_BY_USER_CALL);
export const getActivityByUserDone = createAction(types.GET_ACTIVITY_BY_USER_DONE);
export const getActivityByUserFail = createAction(types.GET_ACTIVITY_BY_USER_FAIL);

export const getUserAnalyticsCall = createAction(types.GET_USER_ANALYTICS_CALL);
export const getUserAnalyticsDone = createAction(types.GET_USER_ANALYTICS_DONE);
export const getUserAnalyticsFail = createAction(types.GET_USER_ANALYTICS_FAIL);

export const getGenderAnalyticsCall = createAction(types.GET_GENDER_ANALYTICS_CALL);
export const getGenderAnalyticsDone = createAction(types.GET_GENDER_ANALYTICS_DONE);
export const getGenderAnalyticsFail = createAction(types.GET_GENDER_ANALYTICS_FAIL);

export const getAgeAnalyticsCall = createAction(types.GET_AGE_ANALYTICS_CALL);
export const getAgeAnalyticsDone = createAction(types.GET_AGE_ANALYTICS_DONE);
export const getAgeAnalyticsFail = createAction(types.GET_AGE_ANALYTICS_FAIL);

export const getWordsReadStatusByUserCall = createAction(types.GET_WORDS_READ_STATUS_BY_USER_CALL);
export const getWordsReadStatusByUserDone = createAction(types.GET_WORDS_READ_STATUS_BY_USER_DONE);
export const getWordsReadStatusByUserFail = createAction(types.GET_WORDS_READ_STATUS_BY_USER_FAIL);
