import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';

import Logo from 'src/components/Logo';
import { MenuBars } from 'src/components/icons';
import { useNavigate } from 'react-router-dom';
import { routePathConfig } from 'src/routes';

import SwitchWorkspace from './SwitchWorkspace';
import SideMenu from '../SideMenu';

const NavigationBarContainer = styled.nav`
  height: ${props => props.theme.navigationBar?.height}px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 0 12px;
  box-shadow: 0px 0px 10px 0px rgb(221 221 221 / 93%);
  z-index: 410;
  position: fixed;
  width: 100%;
  max-width: 100vw;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`;

const GradientText = styled.span`
  padding: 0 4px;
  background: ${props => props.theme.colors.gradient[0]};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: visible;
  display: inline-block;
`;

const Start = styled.div`
  display: flex;
  align-items: center;
`;

const End = styled.div`
  display: flex;
  align-items: center;
`;

export { NavigationBar, Logo, GradientText, Start, End };

const NavigationBar = (): JSX.Element => {
  const navigate = useNavigate();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const handleSideMenuOpen = useCallback(() => {
    setIsSideMenuOpen(true);
  }, []);

  return (
    <>
      <NavigationBarContainer>
        <Start>
          <IconButton onClick={handleSideMenuOpen} size='large'>
            <MenuBars width={20} height={20} />
          </IconButton>
          <div style={{ cursor: 'pointer' }} onClick={() => navigate(routePathConfig.home)}>
            <Logo style={{ fontSize: 24 }} />
          </div>
        </Start>
        <End>
          <SwitchWorkspace />
        </End>
      </NavigationBarContainer>
      <SideMenu isOpen={isSideMenuOpen} handleOnClose={() => setIsSideMenuOpen(false)} />
    </>
  );
};

export default NavigationBar;
