import { createAction } from 'redux-actions';
import * as types from 'src/store/types';

export const initAttractionState = createAction(types.INIT_ATTRACTION_STATE);

export const getAttractionsCall = createAction(types.GET_ATTRACTIONS_CALL);
export const getAttractionsDone = createAction(types.GET_ATTRACTIONS_DONE);
export const getAttractionsFail = createAction(types.GET_ATTRACTIONS_FAIL);

export const createAttractionCall = createAction(types.CREATE_ATTRACTION_CALL);
export const createAttractionDone = createAction(types.CREATE_ATTRACTION_DONE);
export const createAttractionFail = createAction(types.CREATE_ATTRACTION_FAIL);

export const getAttractionCall = createAction(types.GET_ATTRACTION_CALL);
export const getAttractionDone = createAction(types.GET_ATTRACTION_DONE);
export const getAttractionFail = createAction(types.GET_ATTRACTION_FAIL);

export const updateAttractionCall = createAction(types.UPDATE_ATTRACTION_CALL);
export const updateAttractionDone = createAction(types.UPDATE_ATTRACTION_DONE);
export const updateAttractionFail = createAction(types.UPDATE_ATTRACTION_FAIL);

export const deleteAttractionCall = createAction(types.DELETE_ATTRACTION_CALL);
export const deleteAttractionDone = createAction(types.DELETE_ATTRACTION_DONE);
export const deleteAttractionFail = createAction(types.DELETE_ATTRACTION_FAIL);
